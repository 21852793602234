import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Link from "next/link";
import React from "react";
import usePreventOnClickInEditor from "../hooks/usePreventOnClickInEditor";
import type { StyledLinkType } from "./StyledLink";
import StyledLink from "./StyledLink";
import type { GetStorePathVariables, GetStorePath } from "./types/GetStorePath";

const QUERY = gql`
  query GetStorePath($storeId: ID!) {
    store(id: $storeId) {
      id
      pagePath
    }
  }
`;

export interface StoreLinkProps {
  storeId: string;
  type: StyledLinkType;
  openInNewTab?: boolean;
  pathSuffix?: string;
  hash?: string;
  id?: string;
  /**
   * override the path that is used to resolve the menu
   */
  menuPath?: string;
}

const StoreLink: React.FC<StoreLinkProps> = ({
  type,
  storeId,
  children,
  openInNewTab = false,
  pathSuffix = "",
  hash,
  menuPath,
  id = null, // can be used for analytics
}) => {
  const { data } = useQuery<GetStorePath, GetStorePathVariables>(QUERY, {
    skip: !storeId,
    variables: { storeId },
  });
  const onClick = usePreventOnClickInEditor();

  if (!data || !data.store) {
    return (
      <StyledLink type={type} disabled id={id}>
        {children}
      </StyledLink>
    );
  }

  const targetPath = `${data.store.pagePath}${pathSuffix}`;
  const path = `${targetPath}${hash ? `#${hash}` : ""}`;
  const href = `/?path=${targetPath}${menuPath ? `&menuPath=${menuPath}` : ""}`;

  return (
    <Link href={href} as={path} passHref>
      <StyledLink
        id={id}
        type={type}
        target={openInNewTab ? "_blank" : undefined}
        onClick={onClick}
      >
        {children}
      </StyledLink>
    </Link>
  );
};

export default StoreLink;
