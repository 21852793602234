import { selectFont } from "../../../../utils/themeUtils";
import styled from "styled-components";
import type { CellPlugin } from "@react-page/editor";
import dynamic from "next/dynamic";

const Newsletter = dynamic(
  () => import("../../../profile/components/Newsletter"),
);
const NewsletterWrapper = styled(Newsletter)`
  margin-bottom: 30px;
  &&& {
    ${selectFont("textContent")}
  }
`;

const Renderer = () => {
  return <NewsletterWrapper />;
};

// TODO: looks like this is not used anymore..?
const plugin: CellPlugin = {
  Renderer,
  id: "newsletter",
  version: 1,
  title: "Newsletter abonnieren",
  description: "Newsletter abonnieren",
};

export default plugin;
