import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import { SHOP_PATH } from "../../../../../config/locations";
import PageLink from "../../../components/PageLink";
import type { StyledLinkType } from "../../../components/StyledLink";
import { styledLinkEnum } from "../../../components/StyledLink";

const PageIdSelector = dynamic({
  loader: () => import("../../../../admin/editorAdminFields/PageIdSelector"),
  ssr: false,
});

const Icon = dynamic({
  loader: () => import("../../../../icons/components/LinkPageIcon"),
});

export default pluginFactories.createComponentPlugin<{
  pageId: string;
  type: StyledLinkType;
  id?: string;
  anchor?: string;
  openInPopup?: boolean;
  showShopMenu?: boolean;
}>({
  icon: <Icon />,
  type: "VPS/PAGE_LINK",
  object: "mark",
  label: "Page link",
  addHoverButton: true,
  addToolbarButton: true,
  controls: {
    type: "autoform",
    schema: {
      required: ["pageId", "type"],
      type: "object",
      properties: {
        pageId: {
          type: "string",
          uniforms: {
            component: PageIdSelector,
          },
        },
        type: {
          type: "string",
          enum: styledLinkEnum,
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
        anchor: {
          type: "string",
        },
        openInPopup: {
          type: "boolean",
        },
        showShopMenu: {
          type: "boolean",
        },
      },
    },
  },
  getInitialData: () => ({
    type: "inline",
    pageId: "asdf",
  }),
  Component: (props) => (
    <PageLink
      type={props.type ?? "inline"}
      pageId={props.pageId}
      id={props.id}
      hash={props.anchor}
      openInPopup={props.openInPopup}
      menuPath={props.showShopMenu ? SHOP_PATH : undefined}
    >
      {props.children}
    </PageLink>
  ),
});
