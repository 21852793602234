import type { EditorProps } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import styled from "styled-components";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";
import { selectFont } from "../../../utils/themeUtils";
import slatePlugins from "../plugins/slatePlugins";

const Paragraph = styled.p`
  ${selectFont("textDetails")};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }
`;
const infoSlate = slate((def) => ({
  ...def,
  plugins: {
    ...slatePlugins,
    paragraphs: {
      paragraph: def.plugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <Paragraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
  },
}));

const textInfoConfig: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [infoSlate],
};

export default textInfoConfig;
