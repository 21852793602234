import type { CellPlugin } from "@react-page/editor";
import dynamic from "next/dynamic";
import type { Data } from "./Component";

const Component = dynamic(() => import("./Component"));
const plugin: CellPlugin<Data> = {
  Renderer: Component,
  id: "newsletterInput",
  version: 1,
  // IconComponent: ,
  title: "Newsletter Input",
  isInlineable: false,
  description: "Input für die Newsletter Anmeldung",
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        align: {
          type: "string",
          enum: ["left", "center", "right", "inherit"],
        },
      },
    },
  },
  createInitialData: () => ({
    align: "inherit",
  }),
};
export default plugin;
