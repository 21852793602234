import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { UseImages, UseImagesVariables } from "./types/UseImages";

export const GET_FILE = gql`
  query UseImages($references: [FileReferenceInput!]!, $width: Int!) {
    files(input: { references: $references }) {
      id
      thumbUrl(width: $width)
      name
      contentType
    }
  }
`;

const useImages = (
  references: Array<{ bucket: string; fileId: string }>,
  width: number,
) => {
  references = references.filter(
    (reference) => reference?.bucket && reference?.fileId,
  );

  const { data, error, loading } = useQuery<UseImages, UseImagesVariables>(
    GET_FILE,
    {
      variables: {
        references,
        width,
      },
      skip: !references || !references.length,
    },
  );
  const files = data?.files;

  return { files, error, loading };
};

export default useImages;
