import type { CellPlugin } from "@react-page/editor";
import dynamic from "next/dynamic";

const Component = dynamic({
  loader: () => import("./Component"),
  ssr: false,
});

const plugin: CellPlugin<{
  title: string;
}> = {
  Renderer: Component,
  id: "repairKit",
  version: 1,
  // IconComponent: ,
  title: "Repair Kit Form",
  isInlineable: false,
  description: "Repair Kit Form",
  controls: {
    type: "autoform",
    schema: {
      required: ["title"],
      properties: {
        title: {
          type: "string",
          default: "",
        },
      },
    },
  },
};

export default plugin;
