import { slatePlugins } from "@react-page/plugins-slate";
import styled from "styled-components";
import { selectFont } from "../../../../utils/themeUtils";
import { Paragraph } from "./paragraphs";
const BlockQuote = styled.blockquote`
  ${Paragraph.toString()} {
    ${selectFont("quotes")};
    &:before {
      content: "«";
    }
    &:after {
      content: "»";
    }
  }
`;

const quotes = {
  blockQuote: slatePlugins.quotes.blockQuote((def) => ({
    ...def,
    Component: BlockQuote,
  })),
};

export default quotes;
