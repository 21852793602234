import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor, selectFont } from "../../../utils/themeUtils";
import Content from "../../content/components/Content";
import Seo from "../../content/components/Seo";
import BackToOverviewButton from "../../core/components/BackToOverviewButton";
import LoadingPlaceholder from "../../core/components/LoadingPlaceholder";
import { newLineToBreak } from "../../core/lib/stringUtils";
import TipIcon from "../../icons/components/TipIcon";
import { H1, H3 } from "../../layout/components/Heading";
import type { GetTipDetail, GetTipDetailVariables } from "./types/GetTipDetail";

const QUERY = gql`
  query GetTipDetail($id: ID!) {
    tip(id: $id) {
      id
      number
      title
      introText
      hasContent
      content
      htmlTitle
      meta {
        description
      }
    }
  }
`;

const Base = styled.div``;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(TipIcon).attrs({ color: "blueDark" })`
  display: none;
  width: 27px;
  height: 48px;
  margin-right: 15px;
  margin-top: -8px;

  ${mediaQueries.tablet`
      display: inline-flex;
  `}
`;

const Heading = styled(H1)`
  color: ${selectColor("blueDark")};
`;

const Title = styled(H3)`
  margin: 20px 0;

  ${mediaQueries.tablet`
    margin: 30px 0;
  `}
`;

const TipHeadingPlaceholder = styled(LoadingPlaceholder)`
  width: 100%;
  height: 100px;
  overflow: hidden;

  ${mediaQueries.tablet`
    width: 50%;
    height: 50px;
  `}
`;

const TipTitlePlaceholder = styled(LoadingPlaceholder)`
  width: 100%;
  height: 55px;
  overflow: hidden;
  margin-top: 40px;

  ${mediaQueries.tablet`
    width: 70%;
    height: 30px;
  `}
`;

const IntroText = styled.div`
  margin: 20px 0;

  ${mediaQueries.tablet`
    margin: 30px 0;
  `}

  ${selectFont("textContent")}
`;

const TipBodyPlaceholder = styled(LoadingPlaceholder)`
  width: 100%;
  max-width: 100%;
  min-height: 30vh;
  margin-top: 30px;
  overflow: hidden;

  ${mediaQueries.tablet`
    min-width: 45vw;
    min-height: 40vh;
    margin-top: 40px;
  `}
`;

export interface TipDetailProps {
  tipId: string;
  style?: React.CSSProperties;
  className?: string;
  showBackButton?: boolean;
}

const TipDetail: React.FC<TipDetailProps> = ({
  tipId,
  style,
  className,
  showBackButton,
}) => {
  const { data, loading } = useQuery<GetTipDetail, GetTipDetailVariables>(
    QUERY,
    {
      variables: {
        id: tipId,
      },
    },
  );

  const {
    number: tipNumber,
    title,
    htmlTitle,
    meta,
    introText,
    hasContent,
    content,
  } = data?.tip ?? {};

  return (
    <Base style={style} className={className}>
      {data?.tip ? (
        <>
          <Seo
            title={htmlTitle || title}
            description={meta?.description || introText}
            openGraph={{
              title,
              description: meta?.description || introText,
            }}
          />
          <HeadingWrapper>
            <Icon />
            <Heading>Veloplus-Tipp #{tipNumber}</Heading>
          </HeadingWrapper>
          <Title>{title}</Title>
          <IntroText>{newLineToBreak(introText)}</IntroText>

          <Content
            content={content}
            inplaceEditing={{
              adminResource: "Tip",
              fieldName: "content",
              recordId: tipId,
            }}
          />

          {showBackButton ? <BackToOverviewButton /> : null}
        </>
      ) : (
        <>
          <TipHeadingPlaceholder />
          <TipTitlePlaceholder />
          <TipBodyPlaceholder />
        </>
      )}
    </Base>
  );
};

export default TipDetail;
