import React from "react";
import styled from "styled-components";
import dynamic from "next/dynamic";
import type { CellPlugin } from "@react-page/editor";

const StoreMap = dynamic({
  loader: () => import("./StoreMap"),
  ssr: true,
});
const Base = styled.div`
  margin-bottom: 40px;
`;

const Renderer = () => (
  <Base>
    <StoreMap />
  </Base>
);

const plugin: CellPlugin = {
  Renderer,
  id: "storeMap",
  version: 1,
  // IconComponent: ,
  title: "Store Map",
  isInlineable: false,
  description: "Store Map",
};

export default plugin;
