import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { iff, selectGridWidth, selectSize } from "../../../../utils/themeUtils";
import useImage from "../../hooks/useImage";
import type { FileReference } from "../image";
import { imageFileSchemaProperty } from "../image";
import { teaserSlate } from "../teaserWithBackgroundImage";
import { pluginBaseStyle } from "../../utils/pluginBaseStyle";
import type { FocalPoint } from "../../../admin/fileUpload/uploadFocalPoint";
import { getFocalPointBackgroundPosition } from "../../utils/getFocalPointBackgroundPosition";
import type { CellPlugin } from "@react-page/editor";
import image from "../image";
import Box from "../../../layout/components/Box";

const ImageContainer = styled.div`
  ${mediaQueries.desktop`
   flex:0 0 ${selectGridWidth(4)}px;
  `}
  margin-right: -5px;
  position: relative;
`;

const Image = styled.img<{ focalPoint: FocalPoint }>`
  object-position: ${({ focalPoint }) =>
    getFocalPointBackgroundPosition(focalPoint)};
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`;

const Base = styled.div<{ addTopMargin: boolean }>`
  ${pluginBaseStyle}
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  width: 100%;

  ${mediaQueries.desktop`
    ${iff((p) => p.addTopMargin)`
      margin-top: ${selectSize("pluginOptionalTopMargin")}px;
    `}
  `}
`;

const ContentWrapper = styled(Box)`
  padding: 25px;
  padding-bottom: 0px;
  width: 100%;
`;

type State = {
  imageFileRef?: FileReference;
  addTopMargin?: boolean;
  alt?: string;
};

const Renderer = ({
  data: { alt = "", imageFileRef, addTopMargin },
  children = null,
}: {
  data: State;
  children?: any;
}) => {
  const { file } = useImage(imageFileRef, 1024);

  return (
    <Base addTopMargin={addTopMargin}>
      {file ? (
        <ImageContainer>
          <Image src={file.url} alt={alt} focalPoint={file.focalPoint} />
        </ImageContainer>
      ) : null}

      <ContentWrapper>{children}</ContentWrapper>
    </Base>
  );
};

const plugin: CellPlugin<State> = {
  Renderer,
  id: "infobox",
  version: 1,
  // IconComponent: ,
  title: "Info Box",
  description: "Info box mit optionalem Bild",
  controls: {
    type: "autoform",
    schema: {
      properties: {
        imageFileRef: imageFileSchemaProperty({ label: "image" }),
        addTopMargin: {
          type: "boolean",
        },
        alt: {
          type: "string",
          uniforms: {
            multiline: true,
          },
        },
      },
      required: [],
    },
  },
  childConstraints: {
    maxChildren: 1,
  },
  cellPlugins: [teaserSlate, image],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: teaserSlate,
          data: teaserSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.headings.h2,
                  children: ["Platzhalter für Titel - BITTE AUSFÜLLEN"],
                },
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: ["Platzhalter für Inhalt - BITTE AUSFÜLLEN"],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
