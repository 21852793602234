import React from "react";
import externalLink from "./externalLink";
import ExternalLink from "../../../components/ExternalLink";

export default externalLink((def) => ({
  ...def,
  controls: {
    type: "autoform",
    schema: {
      required: ["url"],
      type: "object",
      properties: {
        url: {
          type: "string",
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
      },
    },
  } as any,
  object: "inline",
  Component: ({ url, children, id }) => (
    <ExternalLink type="footer" url={url} id={id}>
      {children}
    </ExternalLink>
  ),
}));
