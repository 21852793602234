import { slatePlugins as orgSlatePlugins } from "@react-page/plugins-slate";
import headings from "./headings";
import links from "./links";
import lists from "./lists";
import paragraphs from "./paragraphs";
import quotes from "./quotes";
import wrapWithIcon from "./wrapWithIcon";
const slatePlugins = {
  headings,
  links,
  paragraphs,
  emphasize: orgSlatePlugins.emphasize,
  lists,
  alignment: orgSlatePlugins.alignment,
  quotes,
  wrapper: {
    wrapWithIcon,
  },
};

export default slatePlugins;
