import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import DocumentLink from "../../../components/DocumentLink";

const DocumentSelector = dynamic({
  loader: () => import("../../../../admin/editorAdminFields/DocumentSelector"),
});

const Icon = dynamic({
  loader: () => import("../../../../icons/components/LinkFileIcon"),
});

export default pluginFactories.createComponentPlugin<{
  documentId: string;
  id?: string;
}>({
  icon: <Icon />,
  type: "VPS/FILE_LINK",
  object: "mark",
  label: "Document link",
  addHoverButton: true,
  addToolbarButton: true,
  controls: {
    type: "autoform",
    schema: {
      required: ["documentId"],
      type: "object",
      properties: {
        documentId: {
          type: "string",
          uniforms: {
            component: DocumentSelector,
          },
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
      },
    },
  },
  Component: (props) => (
    <DocumentLink documentId={props.documentId} id={props.id}>
      {props.children}
    </DocumentLink>
  ),
});
