import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";
import Checkbox from "../../form/components/Checkbox";
import ControlLabel from "../../form/components/ControlLabel";
import type { UseFahrtWind } from "./types/UseFahrtWind";
import type {
  VpsSetMagazineEnabled,
  VpsSetMagazineEnabledVariables,
} from "./types/VpsSetMagazineEnabled";
import type {
  VpsSetManualEnabled,
  VpsSetManualEnabledVariables,
} from "./types/VpsSetManualEnabled";

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  margin-top: 12px;
`;

export interface FahrtWindProps {
  style?: React.CSSProperties;
  className?: string;
}

const USE_FAHRT_WIND_QUERY = gql`
  query UseFahrtWind {
    me {
      _id
      vpsMagazineEnabled
      vpsManualEnabled
    }
  }
`;

const SET_MANUAL_ENABLED = gql`
  mutation VpsSetManualEnabled($enabled: Boolean!) {
    vpsSetManualEnabled(enabled: $enabled) {
      _id
      vpsManualEnabled
    }
  }
`;

const SET_MAGAZIN_ENABLED = gql`
  mutation VpsSetMagazineEnabled($enabled: Boolean!) {
    vpsSetMagazineEnabled(enabled: $enabled) {
      _id
      vpsMagazineEnabled
    }
  }
`;

// 🚴‍♀️💨
const FahrtWind: React.FC<FahrtWindProps> = ({ style, className }) => {
  const { t } = useTranslation("common");

  const { data } = useQuery<UseFahrtWind>(USE_FAHRT_WIND_QUERY, {
    fetchPolicy: "cache-and-network",
    ssr: false,
  });

  const [setMagazineEnabled] = useMutation<
    VpsSetMagazineEnabled,
    VpsSetMagazineEnabledVariables
  >(SET_MAGAZIN_ENABLED);
  const [setManualEnabled] = useMutation<
    VpsSetManualEnabled,
    VpsSetManualEnabledVariables
  >(SET_MANUAL_ENABLED);
  if (!data?.me) {
    return null;
  }

  return (
    <Base style={style} className={className}>
      <CheckboxWrapper>
        <ControlLabel
          control={
            <Checkbox
              checked={data?.me?.vpsMagazineEnabled}
              onChange={() => {
                const userId = data?.me?._id;
                const enabled = !data?.me?.vpsMagazineEnabled;
                setMagazineEnabled({
                  variables: {
                    enabled,
                  },
                  optimisticResponse: {
                    vpsSetMagazineEnabled: {
                      __typename: "ShopUser",
                      _id: userId,
                      vpsMagazineEnabled: enabled,
                    },
                  },
                });
              }}
            />
          }
          label={t("fahrtWind.magazin")}
        />
      </CheckboxWrapper>
      <CheckboxWrapper>
        <ControlLabel
          control={
            <Checkbox
              checked={data?.me?.vpsManualEnabled}
              onChange={() => {
                const userId = data?.me?._id;
                const enabled = !data?.me?.vpsManualEnabled;

                setManualEnabled({
                  variables: {
                    enabled,
                  },
                  optimisticResponse: {
                    vpsSetManualEnabled: {
                      __typename: "ShopUser",
                      _id: userId,
                      vpsManualEnabled: enabled,
                    },
                  },
                });
              }}
            />
          }
          label={t("fahrtWind.manual")}
        />
      </CheckboxWrapper>
    </Base>
  );
};

export default FahrtWind;
