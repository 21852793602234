import React from "react";
import styled from "styled-components";
import { iff, selectColor } from "../../../utils/themeUtils";
import type { Variant } from "./TeaserWithImage";

const Base = styled.div<{
  hasLabelImage: boolean;
  hasAuthorImage: boolean;
  variant?: Variant;
  noHorizontalPadding: boolean;
}>`
  width: 100%;

  padding: 25px;
  ${iff((p) => p.noHorizontalPadding)`
    padding-left: 0px;
    padding-right: 0px;
  `}
  ${iff((p) => p.hasLabelImage)`
    padding-top: 36px;
  `}
  ${iff((p) => p.hasAuthorImage)`
    padding-top: 31px;
  `}
  ${iff(
    (p) =>
      p.variant === "minimal" ||
      p.variant === "minimalCompact" ||
      p.variant === "product",
  )`
    padding-top: 10px;
  `}
  padding-bottom: 0px;
  position: relative;
`;

export const LabelImage = styled.img`
  height: 50px;
  position: absolute;
  top: 0px;
  left: 25px;
  transform: translateY(-50%);
  z-index: 1;
`;

const AuthorImage = styled.img`
  position: absolute;
  top: 0px;
  left: 25px;
  transform: translateY(-60px);
  z-index: 1;
  background-color: ${selectColor("greyLight")};

  object-fit: cover;
  align-self: stretch;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export interface ContentWrapperProps {
  style?: React.CSSProperties;
  className?: string;
  labelImageSrc?: string;
  authorImageSrc?: string;
  variant?: Variant;
  noHorizontalPadding?: boolean;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  style,
  className,
  labelImageSrc,
  authorImageSrc,
  children,
  variant,
  noHorizontalPadding = false,
}) => (
  <Base
    style={style}
    className={className}
    variant={variant}
    noHorizontalPadding={noHorizontalPadding}
    hasLabelImage={Boolean(labelImageSrc)}
    hasAuthorImage={Boolean(authorImageSrc)}
  >
    {labelImageSrc ? <LabelImage src={labelImageSrc} loading="lazy" /> : null}
    {authorImageSrc ? (
      <AuthorImage src={authorImageSrc} loading="lazy" />
    ) : null}
    {children}
  </Base>
);

export default ContentWrapper;
