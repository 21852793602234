import React from "react";
import styled from "styled-components";
import type { Color } from "../../../config/theme";
import { selectColor } from "../../../utils/themeUtils";

const Base = styled.svg<{ color: Color }>`
  g path {
    fill: ${selectColor((p) => p.color)};
  }
  width: 19px;
  height: 34px;
`;

export interface TipIconProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: any;
  color?: Color;
}

const TipIcon: React.FC<TipIconProps> = ({
  style,
  className,
  color = "tip",
}) => (
  <Base viewBox="0 0 19 34" style={style} className={className} color={color}>
    <defs>
      <path id="a" d="M0 0h19v34H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <path
        d="M11.949 15.133a.506.506 0 010 .682l-1.306 1.393h.002l-.949 1.013v4.213h-.445l-.004.002c-.002 0-.002-.002-.005-.002h-.447v-4.215l-.946-1.011v-.002l-1.304-1.391a.51.51 0 010-.682.435.435 0 01.637 0l1.613 1.721v-2.433c0-.265.204-.482.452-.482.249 0 .45.217.45.482v2.435l.308-.33 1.306-1.393a.431.431 0 01.638 0zm.934 15.729l.002.004c0 .398-.303.723-.677.723H6.794c-.372 0-.679-.322-.679-.72v-.724H12.882v.717zm-1.805 2.41c0 .003.002.003.002.005 0 .398-.305.723-.677.723H8.6c-.374 0-.679-.325-.679-.723v-.723H11.078v.718zm-4.963-4.09h6.77v-1.445h-6.77v1.444zm-.002-2.41h6.77V25.33h-6.77v1.444zm10.33-12.079l-.012.025-.01.022-4.327 8.178H6.906L2.581 14.74l-.012-.022-.012-.027a9.116 9.116 0 01-1.204-4.545c0-4.799 3.655-8.697 8.149-8.697 4.49 0 8.145 3.898 8.145 8.697 0 1.61-.417 3.183-1.204 4.547zM9.502 0C4.253 0 0 4.542 0 10.146c0 1.944.513 3.76 1.404 5.306l4.711 8.91h6.77l4.713-8.91A10.618 10.618 0 0019 10.146C19 4.542 14.747 0 9.502 0z"
        mask="url(#b)"
      />
    </g>
  </Base>
);

export default TipIcon;
