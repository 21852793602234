const extractSubPageId = (subPath: string) => {
  if (subPath?.length > 1) {
    const slugWithId = subPath.substr(1).split("?")[0];
    const parts = slugWithId.split("-");
    return parts[parts.length - 1];
  }

  return null;
};

export { extractSubPageId };
