import React from "react";
import ScrollabelGallery from "../../layout/components/ScrollableGallery";
import type { BikeAssortmentTeaserProps } from "./BikeAssortmentTeaser";
import BikeAssortmentTeaser from "./BikeAssortmentTeaser";

export interface BikeAssortmentTeaserListProps {
  style?: React.CSSProperties;
  className?: string;
  teasers: BikeAssortmentTeaserProps[];
}

const BikeAssortmentTeaserList: React.FC<BikeAssortmentTeaserListProps> = ({
  style,
  className,
  teasers = [],
}) => {
  return (
    <ScrollabelGallery
      showIndicators
      startIndex={1}
      style={style}
      className={className}
    >
      {teasers.map((teaser, index) => (
        <BikeAssortmentTeaser key={index} {...teaser} />
      ))}
    </ScrollabelGallery>
  );
};

export default BikeAssortmentTeaserList;
