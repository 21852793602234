import dynamic from "next/dynamic";
import type { CellPlugin } from "@react-page/editor";
import { useRouter } from "next/router";

const JobOverview = dynamic({
  loader: () => import("../../../jobs/components/JobOverview"),
  ssr: true,
});

const plugin: CellPlugin = {
  Renderer: () => {
    const { asPath } = useRouter();
    return <JobOverview path={asPath} />;
  },
  id: "JobsList",
  version: 1,
  title: "Jobs List",
  description: "List all stores jobs",
};

export default plugin;
