import React from "react";
import { imageFileSchemaProperty } from "../image";

import type { FileReference } from "../bikeCategoryTeaserList";
import styled from "styled-components";
import useImages from "../../hooks/useImages";
import { iff } from "../../../../utils/themeUtils";
import LoadingPlaceholder from "../../../core/components/LoadingPlaceholder";
import mediaQueries from "../../../../utils/mediaQueries";
import dynamic from "next/dynamic";
import type { CellPlugin } from "@react-page/editor";

const ImageGallery = dynamic({
  loader: () => import("../../../layout/components/ImageGallery"),
  ssr: true, //yes, true
});
const GalleryWrapper = styled.div<{ readOnly: boolean }>`
  margin: 20px 0;

  ${iff((p) => !p.readOnly)`
    pointer-events: none;
  `}
`;

const maxWidth = 750;

const StyledImageGallery = styled(ImageGallery)<{ align: Align }>`
  max-width: ${maxWidth}px;
  margin: 0;

  ${iff((p) => !p.align || p.align === "center")`
    margin-left: auto;
    margin-right: auto;
  `}

  ${iff((p) => p.align === "right")`
    margin-left: auto;
  `}
`;

export const ImageGalleryPlaceholder = styled(LoadingPlaceholder)<{
  align?: Align;
}>`
  margin: 0;
  max-width: ${maxWidth}px;
  width: 100%;
  min-height: 30vh;
  overflow: hidden;

  ${mediaQueries.tablet`
    min-height: 50vh;
  `}

  max-height: 480px;

  ${iff((p) => !p.align || p.align === "center")`
    margin-left: auto;
    margin-right: auto;
  `}

  ${iff((p) => p.align === "right")`
    margin-left: auto;
  `}
`;

const Renderer = ({ readOnly, data }) => {
  const references = data.images?.map(({ imageFileRef }) => imageFileRef) ?? [];
  const { files } = useImages(references, 1600);
  const items = files?.length
    ? files.map(({ thumbUrl }, index) => ({
        original: thumbUrl,
        thumbnail: thumbUrl,
        description: data.images[index]?.description,
      }))
    : [];

  return (
    <GalleryWrapper readOnly={readOnly}>
      {items.length ? (
        <StyledImageGallery align={data.align} items={items} />
      ) : (
        <ImageGalleryPlaceholder align={data.align} />
      )}
    </GalleryWrapper>
  );
};

type Align = "left" | "center" | "right";
type ImageGalleryItem = {
  imageFileRef: FileReference;
  description?: string;
};

const plugin: CellPlugin<{
  align: Align;
  images: ImageGalleryItem[];
}> = {
  Renderer,
  id: "imageGallery",
  version: 1,
  // IconComponent: ,
  title: "Image gallery",

  description: "Image gallery",

  controls: {
    type: "autoform",
    columnCount: 1,
    schema: {
      required: ["images"],
      properties: {
        align: {
          type: "string",
          enum: ["left", "center", "right"],
        },
        images: {
          type: "array",

          items: {
            type: "object",
            required: [],
            properties: {
              imageFileRef: imageFileSchemaProperty({ label: "Image" }),
              description: {
                type: "string",
              },
            },
          },
        },
      },
    },
  },
  createInitialData: () => ({
    align: "center",
    images: [],
  }),
};

export default plugin;
