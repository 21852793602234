import type { CellPlugin } from "@react-page/editor";
import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import type { FileReference } from "../bikeCategoryTeaserList";
import { FooterBase, FooterTitle } from "../footerColumn";
import { imageFileSchemaProperty } from "../image";
import TrustIcon from "./TrustIcon";

const IconsWrapper = styled.div`
  display: flex;
  align-items: start;
  margin-top: 10px;
  margin-bottom: 18px;

  ${mediaQueries.desktop`
    margin-bottom: 9px;
  `}
`;

const Renderer = ({ readOnly, data }) => {
  const trustIcons = data.trustIcons ?? [];

  return (
    <FooterBase readOnly={readOnly} showOnMobile={data.showOnMobile}>
      <FooterTitle>{data.title}</FooterTitle>
      <IconsWrapper>
        {trustIcons.map(({ href, imageFileRef }, i) => (
          <TrustIcon key={i} href={href} imageFileRef={imageFileRef} />
        ))}
      </IconsWrapper>
    </FooterBase>
  );
};

type Icon = {
  href: string;
  imageFileRef: FileReference;
};

const plugin: CellPlugin<{
  showOnMobile: boolean;
  title: string;
  trustIcons: Icon[];
}> = {
  Renderer,
  id: "footerTrustIcons",
  version: 1,
  // IconComponent: ,
  title: "Footer Trust Icons",
  isInlineable: false,
  description: "Footer Block bestehend aus verlinkten Trust Icons",
  controls: {
    type: "autoform",
    schema: {
      required: ["title"],
      properties: {
        showOnMobile: {
          type: "boolean",
        },
        title: {
          type: "string",
        },

        trustIcons: {
          type: "array",

          items: {
            type: "object",
            required: [],
            properties: {
              href: {
                type: "string",
              },
              imageFileRef: imageFileSchemaProperty({ label: "Image" }),
            },
          },
        },
      },
    },
  },
  createInitialData: () => ({
    showOnMobile: false,
    title: "",
    trustIcons: [],
  }),
};
export default plugin;
