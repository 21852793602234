import { useQuery } from "@apollo/client";
import type { CellPlugin } from "@react-page/editor";
import gql from "graphql-tag";
import dynamic from "next/dynamic";
import React from "react";
import PageLink from "../../components/PageLink";
import { FooterBase, FooterTitle } from "../footerColumn";
import type { GetFooterStores } from "./types/GetFooterStores";

const QUERY = gql`
  query GetFooterStores {
    stores(orderBy: name_ASC) {
      id
      name
      slug
    }
  }
`;

const PageIdSelector = dynamic({
  loader: () => import("../../../admin/editorAdminFields/PageIdSelector"),
  ssr: false,
});

type Data = {
  showOnMobile: boolean;
  title: string;
  storePageId: string;
};

const Renderer = React.memo(
  ({ readOnly, data }: { readOnly: boolean; data: Data }) => {
    const { data: footerData } = useQuery<GetFooterStores>(QUERY);

    return (
      <FooterBase readOnly={readOnly} showOnMobile={data.showOnMobile}>
        <FooterTitle>{data.title}</FooterTitle>
        {footerData?.stores?.length
          ? footerData.stores.map(({ id, name, slug }) => (
              <p key={id}>
                <PageLink
                  type="footer"
                  pageId={data.storePageId}
                  pathSuffix={`/${slug}-${id}`}
                >
                  {name}
                </PageLink>
              </p>
            ))
          : null}
      </FooterBase>
    );
  },
);
const plugin: CellPlugin<Data> = {
  Renderer,
  id: "footerStores",
  version: 1,
  // IconComponent: ,
  title: "Footer Stores",
  isInlineable: false,
  description: "Footer Block bestehend aus einer Liste von Läden",
  controls: {
    type: "autoform",
    schema: {
      required: ["title", "storePageId"],
      properties: {
        showOnMobile: {
          type: "boolean",
        },
        title: {
          type: "string",
        },
        storePageId: {
          type: "string",
          uniforms: {
            component: PageIdSelector,
          },
        },
      },
    },
  },
  createInitialData: () => ({
    showOnMobile: false,
    title: "",
    storePageId: "",
  }),
};
export default plugin;
