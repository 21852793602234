import type { CellPlugin } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { selectColor, selectFont } from "../../../../utils/themeUtils";
import slatePlugins from "../slatePlugins";

const InfobarParagraph = styled.p`
  margin-bottom: 16px;

  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
  ${selectFont("textDetails")};
  ${mediaQueries.desktop`
    ${selectFont("textContent")};
  `}
`;

export const infobarSlate = slate((def) => ({
  ...def,
  id: `${def.id}/infobar`,

  plugins: {
    paragraphs: {
      paragraph: def.plugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <InfobarParagraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    ...pick(slatePlugins, [
      "headings.h2",
      "headings.h3",
      "links",
      "emphasize",
      "quotes",
      "wrapper",
    ]),
  },
}));

const Base = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  ${mediaQueries.desktop`
   border-radius: 5px;
   margin-left: 0px;
   margin-right: 0px;
   width: 100%;
  `}
  display: flex;
  overflow: hidden;
  margin-bottom: 30px;
`;

const ContentWrapper = styled.div`
  background-color: ${selectColor("sand")};

  padding: 16px;
  padding-bottom: 0px;
  width: 100%;
`;

const Renderer = ({ children = null }) => {
  return (
    <Base>
      <ContentWrapper>{children}</ContentWrapper>
    </Base>
  );
};

const infobar: CellPlugin = {
  Renderer,
  id: "infobar",
  version: 1,
  // IconComponent: ,
  title: "Info Bar",
  description: "Infobalken für kurze Infos",
  childConstraints: {
    maxChildren: 1,
  },
  cellPlugins: [infobarSlate],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: infobarSlate,
          data: infobarSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: [
                    "Veloplus Läden an Weihnachten: Bitte beachten Sie die angepassten Öffnungszeiten.",
                  ],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};
export default infobar;
