import type { EditorProps, Value } from "@react-page/editor";
import Editor from "@react-page/editor";

import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";
import useMediaQueries from "../../../hooks/useMediaQueries";
import { ShopRoleAction } from "../../../types/global-types";
import { selectSize } from "../../../utils/themeUtils";
import useMe from "../../profile/hooks/useMe";

import type { ContentConfigSelection } from "../getEditorConfig";
import getEditorConfig from "../getEditorConfig";

const Base = styled.div`
  width: 100%;
  .react-page-cell-inner.slate {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      max-width: ${selectSize("maxSlateWidth")}px;
    }
  }
`;

type InplaceEditingConfig = {
  adminResource: string;
  recordId: string;
  fieldName: string;
};
export interface ContentProps {
  style?: React.CSSProperties;
  className?: string;
  content: any;
  configSelection?: ContentConfigSelection;
  inplaceEditing?: InplaceEditingConfig;
  /** prevent some plugins by id from beeing used. We only use this for a very specific usecase: on the job page we generate html from the editor, but some plugins lead to problems there
   **/
  blackListPlugins?: string[];
}

const WithInplaceEditing: React.FC<
  {
    inplaceEditing: InplaceEditingConfig;
    value: Value;
  } & EditorProps
> = React.memo(
  ({
    inplaceEditing,
    value,
    cellPlugins,
    cellSpacing = CONTENT_EDITOR_CELL_SPACING,
    childConstraints,
  }) => {
    const { permissions } = useMe();
    const { desktop } = useMediaQueries(); // on mobile it does not make sense
    const [show, setShow] = useState(false);

    useEffect(() => {
      // small delay after changing the content
      setShow(false);
      let handle;
      if (inplaceEditing) {
        handle = setTimeout(() => setShow(true), 100);
      }
      return () => clearTimeout(handle);
    }, [inplaceEditing]);
    const allowed =
      show && permissions.includes(ShopRoleAction.manageContent) && desktop;

    const readOnlyEditor = useMemo(() => {
      return (
        <Editor
          cellSpacing={cellSpacing}
          value={value}
          cellPlugins={cellPlugins}
          lang="de"
          readOnly
        />
      );
    }, [value, cellPlugins, cellSpacing]);

    const [C, setC] = useState(null);
    useEffect(() => {
      if (allowed) {
        // we not using dynamic, because we cannot render a loading component with props
        // but we want to show the default editor while loading
        import("./EditorWithInlineEditing").then((c) => setC(() => c.default));
      }
    }, [allowed]);

    if (!allowed || !C) {
      return readOnlyEditor;
    }

    return (
      <C
        inplaceEditing={inplaceEditing}
        value={value}
        cellPlugins={cellPlugins}
        lang="de"
        readOnlyEditor={readOnlyEditor}
        cellSpacing={cellSpacing}
        childConstraints={childConstraints}
      />
    );
  },
);
const Content: React.FC<ContentProps> = ({
  style,
  className,
  content,
  configSelection,
  inplaceEditing,
  blackListPlugins,
}) => {
  if (content == null) {
    return null;
  }

  const { cellPlugins, ...config } = getEditorConfig(configSelection);

  return (
    <Base style={style} className={className}>
      <WithInplaceEditing
        inplaceEditing={inplaceEditing}
        value={content}
        cellPlugins={
          blackListPlugins?.length > 0
            ? cellPlugins.filter((p) => !blackListPlugins.includes(p.id))
            : cellPlugins
        }
        {...config}
      />
    </Base>
  );
};

export default React.memo(Content);
