import { slatePlugins } from "@react-page/plugins-slate";
import styled from "styled-components";
import type { Font } from "../../../../config/theme";
import { selectFont } from "../../../../utils/themeUtils";

export const Paragraph = styled.p<{
  font?: Font;
}>`
  ${selectFont((p) => p.font || "textContent")};
  margin-bottom: 20px;
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
`;

const paragraphs = {
  paragraph: slatePlugins.paragraphs.paragraph((def) => ({
    ...def,
    Component: Paragraph,
  })),
};
export default paragraphs;
