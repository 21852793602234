import { useQuery } from "@apollo/client";
import type { CellPlugin } from "@react-page/editor";
import gql from "graphql-tag";
import dynamic from "next/dynamic";
import React from "react";
import { useTranslation } from "../../../../hooks/useTranslation";
import PageLink from "../../components/PageLink";
import { FooterBase, FooterTitle } from "../footerColumn";
import type { GetFooterJobs } from "./types/GetFooterJobs";

const QUERY = gql`
  query GetFooterJobs {
    jobs(where: { showOnFooter: true }) {
      id
      title
      store {
        name
      }
    }
  }
`;

const PageIdSelector = dynamic({
  loader: () => import("../../../admin/editorAdminFields/PageIdSelector"),
  ssr: false,
});

type Data = {
  showOnMobile: boolean;
  title: string;
  jobPageId: string;
};

const Renderer = React.memo(
  ({ readOnly, data }: { readOnly: boolean; data: Data }) => {
    const { t } = useTranslation();

    const { data: jobData, loading } = useQuery<GetFooterJobs>(QUERY, {
      fetchPolicy: "cache-and-network",
      // see https://github.com/apollographql/apollo-client/issues/7068
      nextFetchPolicy: "cache-first",
      ssr: false,
    });

    return (
      <FooterBase readOnly={readOnly} showOnMobile={data.showOnMobile}>
        <FooterTitle>{data.title}</FooterTitle>
        <PageLink type="footer" pageId={data.jobPageId}>
          {t("jobs.detailHeadline")}
        </PageLink>
        {!loading
          ? jobData?.jobs.map(({ id, title, store }) => (
              <p key={id}>
                <PageLink
                  type="footer"
                  pageId={data.jobPageId}
                  pathSuffix={`/${id}`}
                >
                  {title} in {store?.name}
                </PageLink>
              </p>
            ))
          : null}
      </FooterBase>
    );
  },
);

const plugin: CellPlugin<Data> = {
  Renderer,
  id: "footerJobs",
  version: 1,
  // IconComponent: ,
  title: "Footer Jobs",
  isInlineable: false,
  description: "Footer Block bestehend aus einer Jobliste",
  controls: {
    type: "autoform",
    schema: {
      required: ["title", "jobPageId"],
      properties: {
        showOnMobile: {
          type: "boolean",
        },
        title: {
          type: "string",
        },
        jobPageId: {
          type: "string",
          uniforms: {
            component: PageIdSelector,
          },
        },
      },
    },
  },
  createInitialData: () => ({
    showOnMobile: false,
    title: "",
    jobPageId: "",
  }),
};
export default plugin;
