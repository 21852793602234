import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { UseImage, UseImageVariables } from "./types/UseImage";

export const GET_FILE = gql`
  query UseImage($fileId: String, $bucket: String, $width: Int) {
    file(fileId: $fileId, bucket: $bucket) {
      id
      url: thumbUrl(width: $width)
      name
      contentType
      focalPoint {
        x
        y
      }
    }
  }
`;

const useImage = (
  fileRef: { bucket: string; fileId: string },
  width: number,
) => {
  const { data, error, loading } = useQuery<UseImage, UseImageVariables>(
    GET_FILE,
    {
      variables: {
        ...fileRef,
        width,
      },
      skip: !fileRef || !fileRef.bucket || !fileRef.fileId,
    },
  );
  const file = data?.file;

  return { file, error, loading };
};

export default useImage;
