import type { CellPlugin } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import dynamic from "next/dynamic";
import type { PropsWithChildren } from "react";
import React from "react";
import styled from "styled-components";
import { selectFont } from "../../../../utils/themeUtils";
import Heading from "../../../layout/components/Heading";
import TeaserWithBackgroundImage from "../../../teaser/components/TeaserWithBackgroundImage";
import TeaserWithImage from "../../../teaser/components/TeaserWithImage";
import { useEmployee } from "../../hooks/useEmployee";
import useImage from "../../hooks/useImage";
import type { FileReference } from "../image";
import image, { imageFileSchemaProperty } from "../image";
import slatePlugins from "../slatePlugins";
import { LI, OL, UL } from "../slatePlugins/lists";
import { Paragraph } from "../slatePlugins/paragraphs";

const EmployeeIdSelector = dynamic({
  loader: () => import("../../../admin/editorAdminFields/EmployeeIdSelector"),
  ssr: false,
});

type PluginData = {
  imageFileRef?: FileReference;
  labelImageFileRef?: FileReference;
  heading?: string;
  addHeaderSpacing?: boolean;
  imageUseOriginalAspectRatio?: boolean;
  imageUseOriginalSize?: boolean;
  maxContentWidth?: number;
  authorId?: string;
  teaserType:
    | "normal"
    | "normalGrey"
    | "article"
    | "product"
    | "withBackgroundImage"
    | "minimal"
    | "minimalCompact";
};
export const TeaserParagraph = styled.p`
  ${selectFont("textDetails")};
  margin-bottom: 18px;
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
`;

const Heading2 = styled(Heading).attrs({ level: 3 })`
  margin-bottom: 9px;
`;
const Heading3 = styled(Heading).attrs({ level: 4 })``;

const StyledLi = styled(LI)`
  ${selectFont("textDetails")};
  margin-bottom: 0;

  ${Paragraph.toString()} {
    ${selectFont("textDetails")};
    margin-bottom: 0;
  }
`;

export const teaserSlate = slate((def) => ({
  ...def,
  id: `${def.id}/teaser`,
  plugins: {
    paragraphs: {
      paragraph: def.plugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: TeaserParagraph,
      })),
    },
    headings: {
      h2: def.plugins.headings.h2((d) => ({
        ...d,
        Component: Heading2,
      })),
      h3: def.plugins.headings.h3((d) => ({
        ...d,
        Component: Heading3,
      })),
    },
    ...pick(slatePlugins, ["links", "emphasize", "quotes", "alignment"]),
    lists: {
      ul: slatePlugins.lists.ul({
        customizeList: (def: any) => ({
          ...def,
          Component: UL,
        }),
        customizeListItem: (def: any) => ({
          ...def,
          Component: StyledLi,
        }),
      }),
      ol: slatePlugins.lists.ol({
        customizeList: (def: any) => ({
          ...def,
          Component: OL,
        }),
        customizeListItem: (def: any) => ({
          ...def,
          Component: StyledLi,
        }),
      }),
    },
  },
}));

const COMPONENTS = {
  normal: TeaserWithImage,
  normalGrey: (props) => <TeaserWithImage {...props} variant="grey" />,

  article: (props) => <TeaserWithImage {...props} variant="article" />,
  product: (props) => <TeaserWithImage {...props} variant="product" />,
  minimal: (props) => <TeaserWithImage {...props} variant="minimal" />,
  minimalCompact: (props) => (
    <TeaserWithImage {...props} variant="minimalCompact" />
  ),
  withBackgroundImage: TeaserWithBackgroundImage,
};
const Renderer = ({
  data,
  children = null,
}: PropsWithChildren<{ data: PluginData }>) => {
  const image = useImage(data.imageFileRef, 1680)?.file;
  const labelImage = useImage(data.labelImageFileRef, 300)?.file;
  const { data: authorData } = useEmployee({ id: data.authorId });

  const Component = COMPONENTS[data.teaserType || "normal"];
  if (!Component) {
    return <p>Unkown teaser type: {data.teaserType}</p>;
  }

  return (
    <Component
      maxContentWidth={data.maxContentWidth}
      heading={data.heading}
      addHeaderSpacing={data.addHeaderSpacing}
      imageSrc={image ? image.url : null}
      focalPoint={image?.focalPoint}
      labelImageSrc={labelImage ? labelImage.url : null}
      imageUseOriginalAspectRatio={data.imageUseOriginalAspectRatio}
      imageUseOriginalSize={data.imageUseOriginalSize}
      authorImageSrc={authorData?.employee?.profileImage?.thumbUrl}
    >
      {children}
    </Component>
  );
};
const plugin: CellPlugin<PluginData> = {
  Renderer,
  id: "teaserWithImage",
  version: 1,
  // IconComponent: ,
  title: "Teaser mit bild",
  description: "Teaser mit bild",

  controls: {
    type: "autoform",
    columnCount: 3,
    schema: {
      properties: {
        heading: {
          type: "string",
        },

        teaserType: {
          type: "string",
          enum: [
            "normal",
            "normalGrey",
            "article",
            "withBackgroundImage",
            "minimal",
            "minimalCompact",
            "product",
          ],
        },
        maxContentWidth: {
          type: "integer",
        },

        addHeaderSpacing: {
          type: "boolean",
        },
        imageUseOriginalAspectRatio: {
          type: "boolean",
          default: false,
        },
        imageUseOriginalSize: {
          type: "boolean",
          default: false,
        },
        imageFileRef: imageFileSchemaProperty({ label: "Image" }),
        labelImageFileRef: imageFileSchemaProperty({
          label: "small Label image",
        }),
        authorId: {
          type: "string",
          uniforms: {
            component: EmployeeIdSelector,
          },
        },
      },
      required: [],
    },
  },
  createInitialData: () => ({
    heading: "A title",
    teaserType: "withBackgroundImage",
    maxContentWidth: 670 /* tbd */,
  }),
  childConstraints: {
    maxChildren: 1,
  },
  cellPlugins: [teaserSlate, image],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: teaserSlate,
          data: teaserSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.headings.h2,
                  children: ["Platzhalter für Titel - BITTE AUSFÜLLEN"],
                },
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: ["Platzhalter für Inhalt - BITTE AUSFÜLLEN"],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
