import { css } from "styled-components";

export const multilineEllipsis = (lines: number) => css`
  line-clamp: ${lines};
  -webkit-line-clamp: ${lines};
  box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;
