import type { EditorProps } from "@react-page/editor";
import slate from "@react-page/plugins-slate";

import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";

import fullWidthBox from "../plugins/fullWidthBar";
import htmlPlugin from "../plugins/html";
import image from "../plugins/image";
import imageGallery from "../plugins/imageGallery";
import infoBar from "../plugins/infoBar";
import infoBox from "../plugins/infoBox";
import productSlider from "../plugins/productSlider";
import quoteBlock from "../plugins/quoteBlock";
import slatePlugins from "../plugins/slatePlugins";
import spacingContainer from "../plugins/spacingContainer";
import teaserWithBackgroundImage from "../plugins/teaserWithBackgroundImage";
import jobsListPlugin from "../plugins/jobsList";
import youtubeVideo from "../plugins/youtubeVideo";

export const defaultSlate = slate((def) => ({
  ...def,

  plugins: slatePlugins,
}));

const storeContentAreaConfig: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [
    defaultSlate,
    jobsListPlugin,
    productSlider,
    image,
    imageGallery,
    youtubeVideo,
    htmlPlugin,

    infoBox,
    infoBar,
    fullWidthBox,
    teaserWithBackgroundImage,
    quoteBlock,
    spacingContainer,
  ],
};

export default storeContentAreaConfig;
