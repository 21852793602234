import type { CellPlugin } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { selectFont } from "../../../../utils/themeUtils";
import slatePlugins from "../slatePlugins";
import { Paragraph } from "../slatePlugins/paragraphs";

export const quoteSlate = slate((def) => ({
  ...def,
  id: `${def.id}/quotes`,

  plugins: pick(slatePlugins, ["paragraphs", "links", "emphasize", "quotes"]),
}));

const Base = styled.div`
  margin-top: 58px;
  margin-bottom: 58px;
  padding-top: 58px;
  padding-bottom: 58px;
  border-bottom: 1px solid #edebe2;
  border-top: 1px solid #edebe2;
  ${Paragraph} {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0px;
  }
  blockquote {
    // add space if it contains an author
    &:not(:last-child) {
      margin-bottom: 34px;
      ${mediaQueries.desktop`
        margin-bottom: 53px;
      `}
    }
  }
  blockquote,
  blockquote ${Paragraph} {
    text-align: center;

    ${selectFont("large")};
  }
`;
const Renderer = ({ children = null }) => {
  return <Base>{children}</Base>;
};

const plugin: CellPlugin = {
  Renderer,
  id: "quoteBlock",
  version: 1,
  // IconComponent: ,
  title: "Zitat",
  description: "Zitatblock",
  cellPlugins: [quoteSlate],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: quoteSlate,
          data: quoteSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.quotes.blockQuote,
                  children: [
                    {
                      plugin: plugins.paragraphs.paragraph,
                      children: ["Platzhalter für Titel - BITTE AUSFÜLLEN"],
                    },
                  ],
                },
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: ["Platzhalter für Inhalt - BITTE AUSFÜLLEN"],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
