import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
import { pluginBaseStyle } from "../../content/utils/pluginBaseStyle";
import ContentColumn from "../../layout/components/ContentColumn";
import FullScreenWidth from "../../layout/components/FullScreenWidth";
import Heading from "../../layout/components/Heading";
import PageColumn from "../../layout/components/PageColumn";

export interface FullWidthBoxProps {
  style?: React.CSSProperties;
  className?: string;
  title?: string;
}

const Base = styled.div`
  background-color: ${selectColor("sand")};
  padding-top: 35px;
  padding-bottom: 35px;
  ${pluginBaseStyle};
`;

const Title = styled(Heading).attrs({ level: 3 })`
  margin-bottom: 30px;
`;

const FullWidthBox: React.FC<FullWidthBoxProps> = ({
  style,
  className,
  title,
  children,
}) => (
  <FullScreenWidth>
    <Base style={style} className={className}>
      <PageColumn>
        <ContentColumn>{title ? <Title>{title}</Title> : null}</ContentColumn>
      </PageColumn>

      <PageColumn>
        <ContentColumn>{children}</ContentColumn>
      </PageColumn>
    </Base>
  </FullScreenWidth>
);

export default FullWidthBox;
