import { clamp } from "lodash-es";
import type { FocalPoint } from "../../admin/fileUpload/uploadFocalPoint";

export const getFocalPointBackgroundPosition = (focalPoint: FocalPoint) => {
  // this is a simple aproach, but it works in most of our cases
  const positionX = (focalPoint?.x ?? 0.5) * 2 - 0.5;
  const positionY = (focalPoint?.y ?? 0.5) * 2 - 0.5;

  // clamp to 0,1 so that we do not overshoot
  return `${clamp(positionX, 0, 1) * 100}% ${clamp(positionY, 0, 1) * 100}%`;
};
