import dynamic from "next/dynamic";
import React from "react";
import PageLink from "../../../components/PageLink";
import pageLink from "./pageLink";
const PageIdSelector = dynamic({
  loader: () => import("../../../../admin/editorAdminFields/PageIdSelector"),
  ssr: false,
});
export default pageLink((def) => ({
  ...def,
  controls: {
    type: "autoform",
    schema: {
      required: ["pageId"],
      type: "object",
      properties: {
        pageId: {
          type: "string",
          uniforms: {
            component: PageIdSelector,
          },
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
      },
    },
  } as any,
  object: "inline",
  Component: (props) => (
    <PageLink type="footer" pageId={props.pageId} id={props.id}>
      {props.children}
    </PageLink>
  ),
}));
