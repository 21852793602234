import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { UseFile, UseFileVariables } from "./types/UseFile";

export const GET_FILE = gql`
  query UseFile($fileId: String, $bucket: String) {
    file(fileId: $fileId, bucket: $bucket) {
      id
      src
      name
      contentType
      focalPoint {
        x
        y
      }
    }
  }
`;

const useFile = (
  fileRef: { bucket: string; fileId: string },
  fallback = null,
) => {
  const { data, error, loading, refetch } = useQuery<UseFile, UseFileVariables>(
    GET_FILE,
    {
      variables: fileRef,
      skip: !fileRef,
    },
  );
  const file = data?.file;

  return { file, error, loading, refetch };
};

export default useFile;
