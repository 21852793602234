import type { CellPlugin } from "@react-page/editor";
import slate, { slatePlugins } from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import React from "react";
import styled from "styled-components";
import { selectSize } from "../../../../utils/themeUtils";
import Heading from "../../../layout/components/Heading";
import ourSlatePlugins from "../slatePlugins";
import { Paragraph } from "../slatePlugins/paragraphs";

const IntroParagraph = styled(Paragraph).attrs({ font: "textLead" })`
  &:last-child {
    margin-bottom: 0;
  }
`;

const IntroHeading = styled(Heading).attrs({ level: 1 })`
  margin-bottom: 20px;
`;

export const contentIntroSlate = slate((def) => ({
  ...def,
  plugins: {
    headings: {
      h1: slatePlugins.headings.h1((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <IntroHeading {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    paragraphs: {
      paragraph: slatePlugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <IntroParagraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    ...pick(ourSlatePlugins, ["links", "emphasize", "quotes"]),
  },
}));

const Base = styled.div`
  margin-bottom: ${selectSize("introBottomMargin")}px;
`;

const Renderer = ({ children = null }) => {
  return <Base>{children}</Base>;
};

const plugin: CellPlugin = {
  Renderer,
  id: "introLayout",
  version: 1,
  // IconComponent: ,
  title: "Content Intro",
  description: "Content Intro",
  childConstraints: {
    maxChildren: 1,
  },
  cellPlugins: [contentIntroSlate],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: contentIntroSlate,
          data: contentIntroSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.headings.h1,
                  children: ["Platzhalter für Titel - BITTE AUSFÜLLEN"],
                },
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: ["Platzhalter für Inhalt - BITTE AUSFÜLLEN"],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
