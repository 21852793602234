import { slatePlugins } from "@react-page/plugins-slate";
import styled from "styled-components";
import Heading from "../../../layout/components/Heading";

const Heading1 = styled(Heading).attrs({ level: 2 })`
  margin-bottom: 20px;
  margin-top: 30px;
`;
const Heading2 = styled(Heading).attrs({ level: 3 })`
  margin-bottom: 10px;
  margin-top: 30px;
`;
const Heading3 = styled(Heading).attrs({ level: 4 })``;

const headings = {
  h1: slatePlugins.headings.h1((def) => ({
    ...def,
    Component: Heading1,
  })),
  h2: slatePlugins.headings.h2((def) => ({
    ...def,
    Component: Heading2,
  })),
  h3: slatePlugins.headings.h3((def) => ({
    ...def,
    Component: Heading3,
  })),
};

export default headings;
