import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import ProductLink from "../../../components/ProductLink";
import type { StyledLinkType } from "../../../components/StyledLink";
import { styledLinkEnum } from "../../../components/StyledLink";

const Icon = dynamic({
  loader: () => import("../../../../icons/components/LinkProductIcon"),
});

export default pluginFactories.createComponentPlugin<{
  productId: string;
  type: StyledLinkType;

  id?: string;
}>({
  icon: <Icon />,
  type: "VPS/PRODUCT_LINK",
  object: "mark",
  label: "Product link",
  addHoverButton: true,
  addToolbarButton: true,
  controls: {
    type: "autoform",
    schema: {
      required: ["productId", "type"],
      type: "object",
      properties: {
        productId: {
          type: "string",
        },
        type: {
          type: "string",
          enum: styledLinkEnum,
        },

        id: {
          type: "string",
          title: "Id (for analytics)",
        },
      },
    },
  },
  getInitialData: () => ({
    type: "inline",
    productId: "",
  }),
  Component: (props) => (
    <ProductLink
      type={props.type ?? "inline"}
      productId={props.productId?.trim()}
      id={props.id}
    >
      {props.children}
    </ProductLink>
  ),
});
