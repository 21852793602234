import type { CellPlugin } from "@react-page/editor";
import dynamic from "next/dynamic";
import React from "react";
import type { EmployeeInfoByIdProps } from "../../components/EmployeeInfo";
import { EmployeeInfoById } from "../../components/EmployeeInfo";

const EmployeeIdSelector = dynamic({
  loader: () => import("../../../admin/editorAdminFields/EmployeeIdSelector"),
  ssr: false,
});

const plugin: CellPlugin<EmployeeInfoByIdProps> = {
  Renderer: ({ data }) => {
    return <EmployeeInfoById {...data} />;
  },
  id: "vps/employeeInfo",
  version: 1,

  title: "Employee Info",
  description: "Employee Info",
  controls: {
    type: "autoform",
    schema: {
      required: ["employeeId", "size"],
      properties: {
        employeeId: {
          type: "string",
          uniforms: {
            component: EmployeeIdSelector,
          },
        },
        align: {
          type: "string",
          enum: ["left", "center", "right"],
        },
        size: {
          type: "string",
          enum: ["small", "medium"],
        },
      },
    },
  },
  createInitialState: () => ({
    employeeId: null,
    align: "left",
    size: "small",
  }),
};
export default plugin;
