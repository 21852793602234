import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import dynamic from "next/dynamic";
import Link from "next/link";
import React, { useRef } from "react";
import { PageBaseParts } from "../../navigation/queries";
import useIsInEditor from "../hooks/useIsInEditor";
import usePreventOnClickInEditor from "../hooks/usePreventOnClickInEditor";

import type { StyledLinkType } from "./StyledLink";
import StyledLink from "./StyledLink";
import type { GetPagePath, GetPagePathVariables } from "./types/GetPagePath";

const ContentPagePopup = dynamic(() => import("./ContentPagePopup"), {
  ssr: false,
});

export const GET_PAGE_PATH = gql`
  query GetPagePath($pageId: ID!) {
    page(id: $pageId) {
      ...PageBaseParts
    }
  }
  ${PageBaseParts}
`;

export const usePagePath = (pageId: string) => {
  return useQuery<GetPagePath, GetPagePathVariables>(GET_PAGE_PATH, {
    skip: !pageId,
    variables: { pageId },
  });
};

export interface PageLinkProps {
  pageId: string;
  type: StyledLinkType;
  openInNewTab?: boolean;
  openInPopup?: boolean;
  pathSuffix?: string;
  hash?: string;
  id?: string;
  /**
   * override the path that is used to resolve the menu
   */
  menuPath?: string;
}

const PageLink: React.FC<PageLinkProps> = ({
  type,
  pageId,
  children,
  menuPath,
  openInNewTab = false,
  openInPopup = false,
  pathSuffix = "",
  hash,
  id = null, // can be used for analytics
}) => {
  const { data } = usePagePath(pageId);
  const onClick = usePreventOnClickInEditor();
  const isInEditor = useIsInEditor();
  const ref = useRef<HTMLElement>(null);
  if (!data || !data.page) {
    return (
      <StyledLink type={type} disabled id={id}>
        {children}
      </StyledLink>
    );
  }

  const targetPath = `${data.page.path}${pathSuffix}`;
  const path = `${targetPath}${hash ? `#${hash}` : ""}`;
  const href = `/?path=${targetPath}${menuPath ? `&menuPath=${menuPath}` : ""}`;
  if (openInPopup) {
    return (
      <>
        <StyledLink
          id={id}
          ref={ref}
          type={type}
          target={openInNewTab ? "_blank" : undefined}
          href={path}
        >
          {children}
        </StyledLink>
        <ContentPagePopup
          disabled={isInEditor}
          path={data?.page?.path}
          buttonRef={ref}
        />
      </>
    );
  }
  return (
    <Link href={href} as={path} passHref>
      <StyledLink
        id={id}
        type={type}
        target={openInNewTab ? "_blank" : undefined}
        onClick={onClick}
      >
        {children}
      </StyledLink>
    </Link>
  );
};

export default PageLink;
