import React from "react";
import styled from "styled-components";
import { iff, selectColor } from "../../../../utils/themeUtils";
import Heading from "../../../layout/components/Heading";
import dynamic from "next/dynamic";
import PageLink from "../../components/PageLink";
import { pluginBaseStyle } from "../../utils/pluginBaseStyle";
import FullWidthBox from "../../../teaser/components/FullWidthBox";
import mediaQueries from "../../../../utils/mediaQueries";
import type { CellPlugin, CellPluginComponentProps } from "@react-page/editor";

import { defaultSlate } from "../../configs/mainContentAreaConfig";

const PageIdSelector = dynamic({
  loader: () => import("../../../admin/editorAdminFields/PageIdSelector"),
  ssr: false,
});

const SectionHeading = styled(Heading).attrs({ level: 3 })<{ center: boolean }>`
  margin-bottom: 30px;

  ${iff((p) => p.center)`
    text-align: center;
  `}
`;

const ActionWrapper = styled.div`
  text-align: center;
  margin-top: 15px;
`;

const StandardBase = styled.div<{ showSeparator: boolean }>`
  ${pluginBaseStyle};

  ${iff((p) => p.showSeparator)`
      padding-bottom: 50px;
      border-bottom: 1px solid ${selectColor("black")};
      margin-bottom:
  `}
`;

const BackgroundFillBase = styled(FullWidthBox)<{ showSeparator: boolean }>`
  ${mediaQueries.desktop`
    padding-bottom: 50px;
  `}
`;

type Props = {
  title: string;
  buttonLabel: string;
  pageId: string;
  showSeparator: boolean;
  showBackgroundFill: boolean;
};
const Renderer: React.FC<CellPluginComponentProps<Props>> = ({
  readOnly,
  data,
  children = null,
}) => {
  const Base =
    readOnly && data.showBackgroundFill ? BackgroundFillBase : StandardBase;

  return (
    <Base showSeparator={data.showSeparator}>
      {data.title ? (
        <SectionHeading center={!data.showBackgroundFill}>
          {data.title}
        </SectionHeading>
      ) : null}
      {children}
      <ActionWrapper>
        <PageLink type="buttonMedium" pageId={data.pageId}>
          {data.buttonLabel}
        </PageLink>
      </ActionWrapper>
    </Base>
  );
};

const plugin: CellPlugin<Props> = {
  Renderer,
  id: "actionSection",
  version: 1,
  // IconComponent: ,
  title: "Action Section",
  description: "Section with a title and action button",
  controls: {
    type: "autoform",
    schema: {
      required: ["buttonLabel"],
      properties: {
        title: {
          type: "string",
          default: "Section Titel",
        },
        buttonLabel: {
          type: "string",
          default: "Alles zum Thema",
        },
        pageId: {
          type: "string",
          uniforms: {
            component: PageIdSelector,
          },
        },
        showSeparator: {
          type: "boolean",
        },
        showBackgroundFill: {
          type: "boolean",
        },
      },
    },
  },
  createInitialChildren: () => {
    return [
      [
        {
          plugin: defaultSlate,
          data: defaultSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: ["Section Body"],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
