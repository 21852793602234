import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import ExternalLink from "../../../components/ExternalLink";
import type { StyledLinkType } from "../../../components/StyledLink";
import { styledLinkEnum } from "../../../components/StyledLink";

const Icon = dynamic({
  loader: () => import("../../../../icons/components/LinkExternalIcon"),
});

export default pluginFactories.createComponentPlugin<{
  url: string;
  type: StyledLinkType;
  id?: string;
}>({
  icon: <Icon />,
  type: "VPS/EXTERNAL_LINK",
  object: "mark",
  label: "External link",
  addHoverButton: true,
  addToolbarButton: true,
  controls: {
    type: "autoform",
    schema: {
      required: ["url", "type"],
      type: "object",
      properties: {
        url: {
          type: "string",
        },
        type: {
          type: "string",
          enum: styledLinkEnum,
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
      },
    },
  },
  Component: ({ url, type, children, id }) => (
    <ExternalLink type={type ?? "inline"} url={url} id={id}>
      {children}
    </ExternalLink>
  ),
});
