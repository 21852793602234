import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
import CheckIcon from "../../icons/components/CheckIcon";

const size = 15;

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  style?: React.CSSProperties;
  className?: string;
}

const Base = styled.span`
  display: inline-flex;
  padding: 2px;
`;

const Input = styled.input`
  appearance: none;
  width: ${size}px;
  height: ${size}px;
  line-height: ${size}px;
  box-shadow: inset 0 0 0 1.5px rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
  margin: 0;
  padding: 0;
  & + i {
    display: none;
  }
  &:checked {
    background-color: ${selectColor("petrol")};
    box-shadow: none;
    & + i {
      display: inline;
    }
  }
`;

const Checkmark = styled.i.attrs({
  children: <CheckIcon color="white" style={{ padding: 2 }} />,
})`
  position: relative;
  display: inline-block;
  pointer-events: none;
  width: ${size}px;
  height: ${size}px;
  line-height: ${size}px;
  margin: 0 0 0 ${-size}px;
  padding: 0;
`;

const Checkbox: React.FC<CheckboxProps> = ({ style, className, ...props }) => (
  <Base style={style} className={className}>
    <Input type="checkbox" {...props} />
    <Checkmark />
  </Base>
);

export default Checkbox;
