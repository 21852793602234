import React, { useEffect, useImperativeHandle } from "react";
import styled from "styled-components";
import Heading from "../../layout/components/Heading";
import ScrollabelGallery, {
  useGalleryRef,
} from "../../layout/components/ScrollableGallery";
import { times } from "lodash-es";
import { selectColor } from "../../../utils/themeUtils";
import {
  ProductTileWrapper,
  StyledPlaceholder,
  StyledProductTile,
} from "./ProductSliderItem";

import FullScreenWidth from "../../layout/components/FullScreenWidth";
import ContentColumn from "../../layout/components/ContentColumn";
import PageColumn from "../../layout/components/PageColumn";
import type { BaseProductTileParts } from "../queries/types/BaseProductTileParts";
import type { Color } from "../../../config/theme";

import Link from "next/link";
import ALink from "../../content/components/ALink";

const Base = styled.div<{ backgroundColor: Color }>`
  background-color: ${(p) => selectColor(p.backgroundColor)};
  padding-top: 25px;
  padding-bottom: 40px;
`;

const Title = styled(Heading).attrs({ level: 3 })`
  margin-bottom: 20px;
`;

const LinkContainer = styled.div`
  margin-top: 30px;
`;

export interface ProductSliderProps {
  style?: React.CSSProperties;
  className?: string;
  backgroundColor?: Color;
  maxProductCount?: number;
  title: string;
  products?: readonly BaseProductTileParts[];
  link?: { href?: string; label?: string };
  scrollToStartOnChange?: boolean;
}

const ProductSlider: React.VFC<ProductSliderProps> = ({
  style,
  className,
  backgroundColor = "white",
  maxProductCount,
  title,
  products,
  link,
  scrollToStartOnChange,
}) => {
  const nbPlaceholders = maxProductCount ?? 12;
  const separatorColor = backgroundColor === "white" ? "sand" : undefined;
  const galleryRef = useGalleryRef();

  useEffect(() => {
    galleryRef.current?.scrollToIndex(0);
  }, [galleryRef, scrollToStartOnChange, products]);
  return (
    <FullScreenWidth>
      <Base
        style={style}
        className={className}
        backgroundColor={backgroundColor}
      >
        <PageColumn>
          <ContentColumn>
            <Title>{title}</Title>
            <ScrollabelGallery
              widthInColumns={3}
              buttonsBackgroundColor={backgroundColor}
              separatorColor={separatorColor}
              ref={galleryRef}
            >
              {products?.length
                ? products.map((product) => (
                    <ProductTileWrapper key={product._id}>
                      <StyledProductTile product={product} />
                    </ProductTileWrapper>
                  ))
                : times(nbPlaceholders).map((i) => (
                    <div key={i}>
                      <StyledPlaceholder />
                    </div>
                  ))}
            </ScrollabelGallery>

            {link?.label && link.href && (
              <LinkContainer>
                <Link href={link.href}>
                  <ALink>{link.label}</ALink>
                </Link>
              </LinkContainer>
            )}
          </ContentColumn>
        </PageColumn>
      </Base>
    </FullScreenWidth>
  );
};

export default ProductSlider;
