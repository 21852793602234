import dynamic from "next/dynamic";
import React from "react";
import Rectangle from "react-rectangle";
import styled from "styled-components";
import { selectColor, selectFont } from "../../../../utils/themeUtils";
import SectionHeading from "../../components/SectionHeading";
import Heading from "../../../layout/components/Heading";
import type { CellPlugin } from "@react-page/editor";

const YoutubeVideo = dynamic({
  loader: () => import("../../components/YoutubeVideo"),
});
const ImagePlaceholder = dynamic({
  loader: () => import("@material-ui/icons/Landscape"),
});

const Title = styled(Heading).attrs({ level: 4 })`
  margin-top: 10px;
`;

const Caption = styled.p`
  margin-top: 12px;
  ${selectFont("imageCaption")};
`;

const Placeholder = styled(Rectangle).attrs(() => ({
  aspectRatio: 4 / 3,
  children: <ImagePlaceholder />,
}))`
  background-color: ${selectColor("greyLight")};
  & svg {
    height: 100% !important;
    width: 100% !important;
  }
`;

const Wrapper = styled.div<{ isInEditMode: boolean }>`
  ${(p) => (p.isInEditMode ? "pointer-events: none;" : null)};
  margin-bottom: 40px;
`;
const Renderer = ({
  readOnly,
  data: {
    heading = "",
    addHeaderSpacing = false,
    aspectRatio = null,
    youtubeId,
    title = null,
    caption = null,
    autoplay,
    mute,
  },
}: {
  readOnly: boolean;
  data: CellProps;
}) => {
  const content = youtubeId ? (
    <YoutubeVideo
      videoId={youtubeId}
      aspectRatio={aspectRatio}
      mute={mute}
      autoplay={autoplay}
    />
  ) : (
    <Placeholder />
  );

  return (
    <div>
      {heading || addHeaderSpacing ? (
        <SectionHeading>{heading}</SectionHeading>
      ) : null}
      <Wrapper isInEditMode={!readOnly}>
        {content}
        {title ? (
          <Title style={{ marginBottom: caption ? -10 : 0 }}>{title}</Title>
        ) : null}
        {caption ? <Caption>{caption}</Caption> : null}
      </Wrapper>
    </div>
  );
};
type AspectRatio = {
  w: number;
  h: number;
};

type CellProps = {
  youtubeId: string;
  title?: string;
  caption?: string;
  aspectRatio?: AspectRatio;
  heading?: string;
  addHeaderSpacing?: boolean;
  autoplay?: boolean;
  mute?: boolean;
};
const plugin: CellPlugin<CellProps> = {
  Renderer,
  id: "vps/youtube",
  version: 1,
  // IconComponent: ,
  title: "Youtube Video",
  isInlineable: true,
  description: "Youtube Video",
  controls: {
    type: "autoform",
    schema: {
      required: ["youtubeId"],
      properties: {
        heading: {
          type: "string",
        },
        addHeaderSpacing: {
          type: "boolean",
        },
        autoplay: {
          type: "boolean",
        },
        mute: {
          type: "boolean",
        },
        youtubeId: {
          type: "string",
        },
        aspectRatio: {
          type: "object",
          properties: {
            w: {
              default: 16,
              type: "integer",
            },
            h: {
              default: 9,
              type: "integer",
            },
          },
          required: ["w", "h"],
        },

        title: {
          type: "string",
        },
        caption: {
          type: "string",
          uniforms: {
            multiline: true,
            style: {
              width: 512,
            },
          },
        },
      },
    },
  },
};

export default plugin;
