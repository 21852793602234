import slate from "@react-page/plugins-slate";
import bikeCategoryTeaserList from "../plugins/bikeCategoryTeaserList";
import blogTeaser from "../plugins/blogTeaser";
import featuredProducts from "../plugins/featuredProducts";
import productSlider from "../plugins/productSlider";
import fullWidthBox from "../plugins/fullWidthBar";
import htmlPlugin from "../plugins/html";
import image from "../plugins/image";
import imageGallery from "../plugins/imageGallery";
import fahrtWind from "../plugins/fahrtWind";
import infoBar from "../plugins/infoBar";
import infoBox from "../plugins/infoBox";
import quoteBlock from "../plugins/quoteBlock";
import slatePlugins from "../plugins/slatePlugins";
import teaserWithBackgroundImage from "../plugins/teaserWithBackgroundImage";
import tip from "../plugins/tip";
import tipList from "../plugins/tipList";
import youtubeVideo from "../plugins/youtubeVideo";
import repairKit from "../plugins/repairKit";
import sponsoringRequest from "../plugins/sponsoringRequest";
import newsletter from "../plugins/newsletter";
import newsletterInput from "../plugins/newsletterInput";
import storeMap from "../plugins/storeMap";
import spacingContainer from "../plugins/spacingContainer";
import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";

export const defaultSlate = slate((def) => ({
  ...def,

  plugins: slatePlugins,
}));

const mainContentAreaConfig: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [
    defaultSlate,
    bikeCategoryTeaserList,
    blogTeaser,
    featuredProducts,
    productSlider,
    image,
    imageGallery,
    fahrtWind,
    newsletter,
    newsletterInput,
    tip,
    tipList,
    youtubeVideo,
    htmlPlugin,
    repairKit,
    sponsoringRequest,
    storeMap,

    infoBox,
    infoBar,
    fullWidthBox,
    teaserWithBackgroundImage,
    quoteBlock,
    spacingContainer,
  ],
};

export default mainContentAreaConfig;
