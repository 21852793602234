import dynamic from "next/dynamic";
import React from "react";
import Heading from "../../../layout/components/Heading";
import BikeAssortmentTeaserList from "../../../teaser/components/BikeAssortmentTeaserList";
import { imageFileSchemaProperty } from "../image";
import styled from "styled-components";
import { pluginBaseStyle } from "../../utils/pluginBaseStyle";
import type { CellPlugin } from "@react-page/editor";

const Base = styled.div`
  ${pluginBaseStyle}
`;

export type FileReference = {
  bucket: string;
  fileId: string;
};

const AssortmentIdSelector = dynamic({
  loader: () => import("../../../admin/editorAdminFields/AssortmentIdSelector"),
  ssr: false,
});

type Category = {
  title: string;
  imageFileRef: FileReference;
  bikeAssortmentId: string;
  eBikeAssortmentId: string;
  additionalQuery?: string;
};
const plugin: CellPlugin<{
  title: string;
  categories: Category[];
}> = {
  id: "productCategoryTeaserList",
  version: 1,
  // IconComponent: ,
  title: "Product categories teasers",

  description: "Product categories teasers",
  Renderer: ({ data }) => {
    return (
      <Base>
        <Heading style={{ marginBottom: 20 }}>{data.title}</Heading>
        <BikeAssortmentTeaserList teasers={data.categories} />
      </Base>
    );
  },
  controls: {
    type: "autoform",

    columnCount: 1,

    schema: {
      required: ["categories", "title"],
      properties: {
        title: {
          type: "string",
          default: "Traumbikes für alle",
        },
        categories: {
          type: "array",

          items: {
            type: "object",
            required: [],
            properties: {
              title: {
                type: "string",
              },
              imageFileRef: imageFileSchemaProperty({ label: "Image" }),
              bikeAssortmentId: {
                type: "string",
                uniforms: {
                  component: AssortmentIdSelector,
                  label: "Bike Category",
                },
              },
              eBikeAssortmentId: {
                type: "string",
                uniforms: {
                  component: AssortmentIdSelector,
                  label: "E-Bike Category",
                },
              },
              additionalQuery: {
                type: "string",
              },
            },
          },
        },
      },
    },
  },
};

export default plugin;
