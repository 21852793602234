import type { CellPlugin } from "@react-page/editor";
import slate, { slatePlugins } from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import Head from "next/head";
import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { selectColor, selectFont } from "../../../../utils/themeUtils";
import type { FocalPoint } from "../../../admin/fileUpload/uploadFocalPoint";
import Heading from "../../../layout/components/Heading";
import PageColumn from "../../../layout/components/PageColumn";
import useImage from "../../hooks/useImage";
import { getFocalPointBackgroundPosition } from "../../utils/getFocalPointBackgroundPosition";
import type { FileReference } from "../image";
import { imageFileSchemaProperty } from "../image";
import ourSlatePlugins from "../slatePlugins";

const LandingHeading = styled(Heading)`
  color: ${selectColor("white")};
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 10px;
  ${mediaQueries.desktop`
    font-size: 78px;
    line-height: 77px;
    margin-bottom: 25px;
  `}

  letter-spacing: 2.27px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 690px;

  & a,
  & a:hover {
    text-decoration: none;
    font-weight: normal;
    color: ${selectColor("white")};
  }
`;

const LandingParagraph = styled.p`
  ${selectFont("tabTitle")};
  ${mediaQueries.desktop`
   ${selectFont("medium")};

  `}
  margin-bottom: 20px;
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
  color: ${selectColor("white")};
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 690px;
  & button,
  & a {
    text-shadow: none;
  }
`;
export const landingIntroSlate = slate((def) => ({
  ...def,
  plugins: {
    headings: {
      h1: slatePlugins.headings.h1((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <LandingHeading {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    paragraphs: {
      paragraph: slatePlugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <LandingParagraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    ...pick(ourSlatePlugins, ["links", "emphasize", "quotes"]),
  },
}));

const Base = styled.div<{ focalPoint?: FocalPoint }>`
  position: relative;
  background-size: cover;
  background-position: ${(p) => getFocalPointBackgroundPosition(p.focalPoint)};

  min-height: 340px;
  display: flex;
  flex-direction: column;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.15) 100%
    );

    ${mediaQueries.desktop`
      right: 50%;
    `}
  }
`;

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  margin-top: auto;
  width: 100%;

  ${mediaQueries.desktop`
    padding-top: 128px;
    padding-bottom: 100px;
  `}
`;

const Renderer = ({ data, children = null }) => {
  const { file } = useImage(data.headerImage, 2048);

  return (
    <>
      {file ? (
        <Head>
          <link rel="preload" as="image" href={file.url}></link>
        </Head>
      ) : null}
      <Base
        focalPoint={file?.focalPoint}
        style={{
          backgroundImage: file ? `url("${file.url}")` : undefined,
        }}
      >
        <Wrapper>
          <PageColumn>{children}</PageColumn>
        </Wrapper>
      </Base>
    </>
  );
};

const plugin: CellPlugin<{ headerImage?: FileReference }> = {
  Renderer,
  id: "introLayout",
  version: 1,
  // IconComponent: ,
  title: "Landing Intro",
  description: "Landing Intro",
  controls: {
    type: "autoform",
    schema: {
      properties: {
        headerImage: imageFileSchemaProperty({ label: "Image" }),
      },
      required: [],
    },
  },
  childConstraints: {
    maxChildren: 1,
  },
  cellPlugins: [landingIntroSlate],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: landingIntroSlate,
          data: landingIntroSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.headings.h1,
                  children: ["Platzhalter für Titel - BITTE AUSFÜLLEN"],
                },
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: ["Platzhalter für Inhalt - BITTE AUSFÜLLEN"],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
