import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";
import mediaQueries from "../../../utils/mediaQueries";
import { multilineEllipsis } from "../../../utils/multilineEllipsis";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";
import Popup from "../../core/components/Popup";
import { newLineToBreak } from "../../core/lib/stringUtils";
import TipIcon from "../../icons/components/TipIcon";

import TipDetail from "./TipDetail";

const Base = styled.div`
  background-color: ${selectColor("sand")};
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  height: 100%;
`;

export interface CategoryIntroTipProps {
  style?: React.CSSProperties;
  className?: string;
  id: string;
  number: number;
  introText: string;

  hasContent: boolean;
  title: string;
}

const Intro = styled.p`
  ${selectFont("textDetails")};
  color: ${selectColor("black")};
`;

const Title = styled.h3`
  ${selectFont("textDetailsBold")};
  color: ${selectColor("black")};
`;

const Body = styled.div``;

const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;
  ${multilineEllipsis(9)}
`;
const Icon = styled(TipIcon)`
  width: 27px;
  height: 48px;
  margin-bottom: 12px;
`;
const ReadMoreButton = styled.button.attrs({
  type: "button",
})`
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin-top: 4px;
  color: ${selectColor("petrol")};
  ${selectFont("textDetailsSemiBold")}
  &:hover {
    text-decoration: underline;
    color: ${selectColor("petrolHover")};
  }
`;

const CategoryIntroTip: React.FC<CategoryIntroTipProps> = ({
  style,
  className,
  id,
  number,
  introText,
  title,
}) => {
  const { t } = useTranslation();
  const [isContentPopupOpen, setIsContentPopupOpen] = useState(false);
  const readMoreButtonRef = useRef<HTMLDivElement>();

  return (
    <Base style={style} className={className}>
      <Icon color={"black"} />

      <Body>
        <TextWrapper>
          <Title>{title}</Title>
          <Intro>{newLineToBreak(introText)}</Intro>
        </TextWrapper>
        <div ref={readMoreButtonRef}>
          <ReadMoreButton>Veloplus-Tipp #{number}</ReadMoreButton>
        </div>
      </Body>
      <Popup
        isDialog={true}
        parentRef={readMoreButtonRef}
        isOpen={isContentPopupOpen}
        setIsOpen={setIsContentPopupOpen}
        theme="white"
      >
        <TipDetail tipId={id} />
      </Popup>
    </Base>
  );
};

export default React.memo(CategoryIntroTip);
