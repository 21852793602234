import { slatePlugins } from "@react-page/plugins-slate";
import styled from "styled-components";
import { Paragraph } from "./paragraphs";
import { selectFont } from "../../../../utils/themeUtils";

export const LI = styled.li`
  ${selectFont("textContent")};
  margin-left: 18px;
  margin-bottom: 10px;

  ${Paragraph.toString()} {
    ${selectFont("textContent")};
    margin-bottom: 0px;
  }

  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
`;

export const UL = styled.ul`
  margin-bottom: 20px;
  li {
    list-style-type: disc;
  }
`;

export const OL = styled.ol`
  margin-bottom: 20px;
  li {
    list-style-type: decimal;
  }
`;

const lists = {
  ul: slatePlugins.lists.ul({
    customizeList: (def: any) => ({
      ...def,
      Component: UL,
    }),
    customizeListItem: (def: any) => ({
      ...def,
      Component: LI,
    }),
  }),
  ol: slatePlugins.lists.ol({
    customizeList: (def: any) => ({
      ...def,
      Component: OL,
    }),
    customizeListItem: (def: any) => ({
      ...def,
      Component: LI,
    }),
  }),
};

export default lists;
