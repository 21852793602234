import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";
import footerColumn, { footerSlate } from "../plugins/footerColumn";
import footerJobs from "../plugins/footerJobs";
import footerStores from "../plugins/footerStores";
import footerTrustIcons from "../plugins/footerTrustIcons";

const footerCollection: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [
    footerSlate,
    footerTrustIcons,
    footerJobs,
    footerStores,
    footerColumn,
  ],
};

export default footerCollection;
