import type { PageTemplate } from "../../types/global-types";
import type { EditorProps } from "@react-page/editor";
import contentPageIntroConfig from "./configs/contentPageIntroConfig";
import footerCollection from "./configs/footerCollection";
import footerNewsletterConfig from "./configs/footerNewsletterConfig";
import landingContentAreaConfig from "./configs/landingContentAreaConfig";
import landingIntroConfig from "./configs/landingIntroConfig";
import mainContentAreaConfig from "./configs/mainContentAreaConfig";
import rootPageContentAreaConfig from "./configs/rootPageContentAreaConfig";
import rootPageIntroConfig from "./configs/rootPageIntroConfig";
import textInfoConfig from "./configs/textInfoConfig";
import storeContentAreaConfig from "./configs/storeContentAreaConfig";
import jobPageConfig from "./configs/jobPageConfig";
import { introConfig } from "./configs/IntroConfig";

export type PageConfigSelection = {
  resource: "Page";
  area: "content" | "intro";
  pageTemplate: PageTemplate;
};

export type ConfigConfigSelection = {
  resource: "Config";
  area: "footer" | "footerNewsletter" | "textInfo";
};

export type AssortmentContentSelection = {
  resource: "AssortmentContent";
  area: "intro" | "contentBottom";
};

export type BrandContentSelection = {
  resource: "Brand";
  area: "intro" | "contentBottom";
};

export type StoreDetailContentSelection = {
  resource: "Store";
};

export type ContentConfigSelection =
  | PageConfigSelection
  | ConfigConfigSelection
  | AssortmentContentSelection
  | BrandContentSelection
  | StoreDetailContentSelection;

const getEditorConfig = (selection?: ContentConfigSelection): EditorProps => {
  if (!selection) {
    return mainContentAreaConfig;
  }
  if (selection.resource === "Store") {
    return storeContentAreaConfig;
  }
  if (selection.resource === "Page") {
    if (selection.pageTemplate === "JOBS" && selection.area !== "intro") {
      return jobPageConfig;
    }

    if (selection.pageTemplate === "LANDING") {
      if (selection.area === "intro") {
        return landingIntroConfig;
      } else {
        return landingContentAreaConfig;
      }
    }

    if (selection.pageTemplate === "ROOT") {
      if (selection.area === "intro") {
        return rootPageIntroConfig;
      } else {
        return rootPageContentAreaConfig;
      }
    }

    if (selection.area === "intro") {
      return contentPageIntroConfig;
    }
    return mainContentAreaConfig;
  } else if (selection.resource === "Config") {
    if (selection.area === "footer") {
      return footerCollection;
    } else if (selection.area === "footerNewsletter") {
      return footerNewsletterConfig;
    } else if (selection.area === "textInfo") {
      return textInfoConfig;
    } else {
      return mainContentAreaConfig;
    }
  } else if (selection.resource === "AssortmentContent") {
    if (selection.area === "intro") {
      return introConfig;
    } else {
      return landingContentAreaConfig;
    }
  } else if (selection.resource === "Brand") {
    if (selection.area === "intro") {
      return introConfig;
    }
  }
};

export default getEditorConfig;
