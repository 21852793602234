import Link from "next/link";
import React from "react";
import useAssortmentNavItem from "../../categories/hooks/useAssortmentNavItem";
import usePreventOnClickInEditor from "../hooks/usePreventOnClickInEditor";
import type { StyledLinkType } from "./StyledLink";
import StyledLink from "./StyledLink";

export interface CategoryLinkProps {
  assortmentId: string;
  type: StyledLinkType;
  openInNewTab?: boolean;
  additionalQuery?: string;
  id?: string;
}

const CategoryLink: React.FC<CategoryLinkProps> = ({
  type,
  assortmentId,
  children,
  openInNewTab = false,
  additionalQuery,
  id,
}) => {
  const { assortmentNavItem } = useAssortmentNavItem({
    assortmentId,
    additionalQuery,
  });
  const onClick = usePreventOnClickInEditor();

  if (!assortmentNavItem) {
    return (
      <StyledLink type={type} disabled id={id}>
        {children}
      </StyledLink>
    );
  }

  return (
    <Link href={assortmentNavItem.href} as={assortmentNavItem.path} passHref>
      <StyledLink
        type={type}
        target={openInNewTab ? "_blank" : undefined}
        onClick={onClick}
        id={id}
      >
        {children}
      </StyledLink>
    </Link>
  );
};

export default CategoryLink;
