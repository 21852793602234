import type { CellPlugin } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { selectFont } from "../../../../utils/themeUtils";
import { H3 } from "../../../layout/components/Heading";
import newsletterInput from "../newsletterInput";
import slatePlugins from "../slatePlugins";
import { Paragraph } from "../slatePlugins/paragraphs";

const Base = styled.div`
  max-width: 700px;

  ${mediaQueries.desktop`
    margin: 0 auto;
    text-align: center;
  `}
`;

const Title = styled(H3)`
  ${selectFont("footerNewsletterTitle")};
  margin-bottom: 5px;
`;

const Description = styled(Paragraph).attrs({
  font: "footerNewsletterDescription",
})`
  display: none;
  margin-bottom: 20px;

  ${mediaQueries.desktop`
    display: block;
    text-align: left;
  `}
`;

export const footerNewsletterSlate = slate((def) => ({
  ...def,
  id: `${def.id}/footerNewsletter`,

  plugins: {
    headings: {
      h1: def.plugins.headings.h1((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <Title {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    paragraphs: {
      paragraph: def.plugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <Description {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    ...pick(slatePlugins, ["wrapper", "links", "emphasize", "quotes"]),
  },
}));

const Renderer = ({ children = null }) => {
  return <Base>{children}</Base>;
};

const footerNewsletter: CellPlugin = {
  Renderer,
  id: "footerNewsletter",
  version: 1,
  // IconComponent: ,
  title: "Footer Newsletter",
  description: "Newsletter Bereich im Page Footer",
  cellPlugins: [footerNewsletterSlate, newsletterInput],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: footerNewsletterSlate,
          data: footerNewsletterSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.headings.h1,
                  children: ["VELONEWS - damit Sie auf dem Laufenden bleiben"],
                },
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: [
                    "Abonnieren Sie unseren Newsletter und erhalten Sie monatlich spannende Velo-Geschichten, Exklusive Angebote und Persönliche Empfehlungen. Als VELONEWS-Abonnent profitieren Sie von regelmässigen Gutscheinaktionen.",
                  ],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default footerNewsletter;
