import type { CellPlugin } from "@react-page/editor";
import ToggleBox from "../components/ToggleBox";

export const toggleBox: CellPlugin<{
  toggleOnMobile?: boolean;
  toggleOnDesktop?: boolean;
  titleCollapsed?: string;
  titleOpen?: string;
}> = {
  id: "togglebox",
  title: "Toggle Box",
  version: 1,
  Renderer: ({ data, ...props }) => <ToggleBox {...data} {...props} />,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        titleOpen: {
          type: "string",
          default: "Nur Produkte anzeigen",
        },
        titleCollapsed: {
          type: "string",
          default: "Informationen anzeigen",
        },
        toggleOnMobile: {
          type: "boolean",
          default: true,
        },
        toggleOnDesktop: {
          type: "boolean",
          default: false,
        },
      },
    },
  },
};
