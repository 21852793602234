import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";

import landingIntro from "../plugins/landingIntro";

const landingIntroConfig: EditorProps = {
  childConstraints: {
    maxChildren: 1,
  },
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [landingIntro],
};

export default landingIntroConfig;
