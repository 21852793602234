import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import styled from "styled-components";
import useFile from "../../hooks/useFile";
import type { FileReference } from "../image";
import { imageFileSchemaProperty } from "../image";
const ArtTrackIcon = dynamic(() => import("@material-ui/icons/ArtTrack"));
const Base = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.div`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  align-self: stretch;
`;
const Text = styled.div`
  margin-left: 15px;
`;
const GREY_IMAGE =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM88x8AAp0BzdNtlUkAAAAASUVORK5CYII=";
export default pluginFactories.createComponentPlugin<{
  image: FileReference;

  width: number;
  imagePadding: number;
  position: string;
}>({
  addHoverButton: true,
  addToolbarButton: true,
  type: "WrapWithImage",

  label: "Wrap with icon / image",
  object: "block",
  icon: <ArtTrackIcon />,
  Component: (props) => {
    // we use `useFile` here and not `useImage` as we dont want to thumbor it
    const fileUrl = useFile(props.image)?.file?.src ?? GREY_IMAGE;
    const imageWidth = props.width ?? 50;
    const position = props.position ?? "center";
    const imagePadding = props.imagePadding ?? 10;
    return (
      <Base data-slate-void="true">
        <Image
          style={{
            backgroundImage: `url("${fileUrl}")`,
            margin: imagePadding,
            backgroundPosition: position,

            minWidth: imageWidth,
          }}
        />
        <Text>{props.children}</Text>
      </Base>
    );
  },
  controls: {
    type: "autoform",
    schema: {
      type: "object",
      required: ["image"],
      properties: {
        image: imageFileSchemaProperty({ label: "Icon" }),
        width: {
          type: "integer",
          default: 50,
        },
        imagePadding: {
          type: "integer",
          default: 10,
        },
        position: {
          type: "string",
          enum: ["top", "center", "botto"],
          default: "center",
        },
      },
    },
  },
});
