import bikeCategoryTeaserList from "../plugins/bikeCategoryTeaserList";
import blogTeaser from "../plugins/blogTeaser";
import featuredProducts from "../plugins/featuredProducts";
import productSlider from "../plugins/productSlider";
import fullWidthBox from "../plugins/fullWidthBar";
import actionSection from "../plugins/actionSection";
import employeeInfo from "../plugins/employeeInfo";
import htmlPlugin from "../plugins/html";
import image from "../plugins/image";
import imageGallery from "../plugins/imageGallery";
import fahrtWind from "../plugins/fahrtWind";
import infoBar from "../plugins/infoBar";
import infoBox from "../plugins/infoBox";
import quoteBlock from "../plugins/quoteBlock";
import teaserWithBackgroundImage from "../plugins/teaserWithBackgroundImage";
import tip from "../plugins/tip";
import youtubeVideo from "../plugins/youtubeVideo";
import repairKit from "../plugins/repairKit";
import sponsoringRequest from "../plugins/sponsoringRequest";
import { defaultSlate } from "./mainContentAreaConfig";
import spacingContainer from "../plugins/spacingContainer";
import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";

const rootPageContentAreaConfig: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [
    defaultSlate,

    bikeCategoryTeaserList,
    blogTeaser,
    employeeInfo,
    featuredProducts,
    productSlider,
    image,
    imageGallery,
    fahrtWind,
    tip,
    youtubeVideo,
    htmlPlugin,
    repairKit,
    sponsoringRequest,

    actionSection,
    infoBox,
    infoBar,
    fullWidthBox,
    teaserWithBackgroundImage,
    quoteBlock,
    spacingContainer,
  ],
};

export default rootPageContentAreaConfig;
