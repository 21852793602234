import Link from "next/link";
import React from "react";
import Rectangle from "react-rectangle";
import styled from "styled-components";
import { makeBrandNavItem } from "../../navigation/utils/makeNavItemFromAssortment";
import type { GetTopBrands_topBrands } from "../hooks/types/GetTopBrands";

export const ASPECT_RATION = 4 / 3;

const Brand = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 100%;
    display: block;
    object-fit: contain;
    max-height: 100%;
  }
`;

export type BrandItemProps = {
  style?: React.CSSProperties;
  className?: string;
  brand: GetTopBrands_topBrands;
};

const BrandItem: React.FC<BrandItemProps> = ({ style, className, brand }) => {
  if (!brand.assortment) {
    return null;
  }
  const navItem = makeBrandNavItem(brand.assortment);

  return (
    <Link key={brand.id} href={navItem.href} as={navItem.path}>
      <a style={style} className={className}>
        <Rectangle aspectRatio={ASPECT_RATION}>
          <Brand>
            {brand.logo ? (
              <img
                loading="lazy"
                alt={brand.assortment.texts?.title}
                src={brand.logo.src}
              />
            ) : (
              brand.assortment.texts?.title
            )}
          </Brand>
        </Rectangle>
      </a>
    </Link>
  );
};

export default BrandItem;
