import { selectFont } from "../../../../utils/themeUtils";
import styled from "styled-components";
import type { CellPlugin } from "@react-page/editor";

import FahrtWind from "../../../profile/components/FahrtWind";

const FahrtWindWrapper = styled(FahrtWind)`
  margin-bottom: 30px;
  &&& {
    ${selectFont("textContent")}
  }
`;

const Renderer = () => {
  return <FahrtWindWrapper />;
};

const plugin: CellPlugin = {
  Renderer,
  id: "fahrtWind",
  version: 1,
  title: "Magazin / Manual abonnieren",

  description: "Magazin / Manual abonnieren",
};
export default plugin;
