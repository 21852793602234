import Link from "next/link";
import React from "react";
import useProductCanonicalNavItem from "../../product/hooks/useProductCanonicalNavItem";
import usePreventOnClickInEditor from "../hooks/usePreventOnClickInEditor";
import type { StyledLinkType } from "./StyledLink";
import StyledLink from "./StyledLink";

export interface ProductLinkProps {
  productId: string;
  type: StyledLinkType;
  openInNewTab?: boolean;
  id?: string;
}
const ProductLink: React.FC<ProductLinkProps> = ({
  type,
  productId,
  children,
  openInNewTab = false,

  id,
}) => {
  const productNavItem = useProductCanonicalNavItem(productId);
  const onClick = usePreventOnClickInEditor();

  if (!productNavItem) {
    return (
      <StyledLink type={type} disabled id={id}>
        {children}
      </StyledLink>
    );
  }

  return (
    <Link href={productNavItem.href} as={productNavItem.path} passHref>
      <StyledLink
        type={type}
        target={openInNewTab ? "_blank" : undefined}
        onClick={onClick}
        id={id}
      >
        {children}
      </StyledLink>
    </Link>
  );
};

export default ProductLink;
