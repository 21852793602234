import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";
import contentIntro from "../plugins/contentIntro";

const contentPageIntroConfig: EditorProps = {
  childConstraints: {
    maxChildren: 1,
  },
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [contentIntro],
};

export default contentPageIntroConfig;
