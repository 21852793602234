import Link from "next/link";
import React from "react";
import Rectangle from "react-rectangle";
import styled from "styled-components";
import useAssortmentNavItem from "../../categories/hooks/useAssortmentNavItem";
import useImage from "../../content/hooks/useImage";
import type { FileReference } from "../../content/plugins/image";
import { LinkButton } from "../../layout/components/Button";
import Heading from "../../layout/components/Heading";
import { selectFont } from "../../../utils/themeUtils";

const Base = styled.div``;
const Teaser = styled(Rectangle).attrs({
  aspectRatio: 5 / 4,
})`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export interface BikeAssortmentTeaserProps {
  style?: React.CSSProperties;
  className?: string;
  title: string;
  imageFileRef: FileReference;
  bikeAssortmentId: string;
  eBikeAssortmentId: string;
  additionalQuery?: string;
}

const Buttons = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  align-items: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.59) 99.93%
  );
  & > * {
    flex: 1;
    margin-bottom: 12px;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  ${selectFont("bikeTeaserLink")};
  padding-top: 0;
  padding-bottom: 0;
`;

const Separator = styled.div`
  box-sizing: border-box;
  height: 56px;
  width: 1px;
  flex: 0 0 1px;
  background-color: white;
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
`;

const BikeAssortmentTeaser: React.FC<BikeAssortmentTeaserProps> = ({
  style,
  className,
  title,
  imageFileRef,
  bikeAssortmentId,
  eBikeAssortmentId,
  additionalQuery,
}) => {
  const { assortmentNavItem: bikeAssortmentNavItem } = useAssortmentNavItem({
    assortmentId: bikeAssortmentId,
    additionalQuery,
  });
  const { assortmentNavItem: ebikeAssortmentNavItem } = useAssortmentNavItem({
    assortmentId: eBikeAssortmentId,
    additionalQuery,
  });

  const { file } = useImage(imageFileRef, 800);

  return (
    <Base style={style} className={className}>
      <Heading style={{ marginBottom: 5 }} level={3}>
        {title}
      </Heading>

      <Teaser
        style={{
          backgroundImage: file ? `url("${file?.url}")` : null,
        }}
      >
        <Inner>
          <Buttons>
            {bikeAssortmentNavItem ? (
              <Link
                passHref
                href={bikeAssortmentNavItem.href}
                as={bikeAssortmentNavItem.path}
              >
                <StyledLinkButton size="medium" variant="transparent">
                  Velos
                </StyledLinkButton>
              </Link>
            ) : null}

            {ebikeAssortmentNavItem ? (
              <>
                {bikeAssortmentNavItem ? <Separator /> : null}
                <Link
                  passHref
                  href={ebikeAssortmentNavItem.href}
                  as={ebikeAssortmentNavItem.path}
                >
                  <StyledLinkButton size="medium" variant="transparent">
                    E-Bikes
                  </StyledLinkButton>
                </Link>
              </>
            ) : null}
          </Buttons>
        </Inner>
      </Teaser>
    </Base>
  );
};

export default React.memo(BikeAssortmentTeaser);
