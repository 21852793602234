import type { CellPlugin } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import { pick, times } from "lodash-es";

import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { selectFont } from "../../../../utils/themeUtils";
import { H3, H4 } from "../../../layout/components/Heading";
import FullWidthBox from "../../../teaser/components/FullWidthBox";
import slatePlugins from "../slatePlugins";
import { Paragraph } from "../slatePlugins/paragraphs";

const Heading1 = styled(H3)``;
// this is a bit a special font
const Heading2 = styled(H4)`
  ${selectFont("mediumSmall")};
  margin-bottom: 20px;
`;
const BarParagraph = styled(Paragraph)`
  margin-bottom: 10px;
`;
export const fullWidthBarSlate = slate((def) => ({
  ...def,
  id: `${def.id}/fullWidthBar`,

  plugins: {
    headings: {
      h1: def.plugins.headings.h1((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <Heading1 {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
      h2: def.plugins.headings.h2((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <Heading2 {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    paragraphs: {
      paragraph: def.plugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <BarParagraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    ...pick(slatePlugins, ["wrapper", "links", "emphasize", "quotes"]),
  },
}));

const Base = styled(FullWidthBox)`
  padding-top: 0px;
  ${mediaQueries.desktop`
      padding-bottom: 20px;
    `}
  .react-page-cell {
    margin-top: 30px;
    margin-bottom: 20px;
    ${mediaQueries.desktop`
      margin-bottom: 0px;
      margin-bottom: 0px;
    `}
  }
`;
const Renderer = ({ children = null }) => {
  return <Base>{children}</Base>;
};

const fullWidthBox: CellPlugin = {
  Renderer,
  id: "fullWidthBox",
  version: 1,
  // IconComponent: ,
  title: "Störer",
  description: "Eine grauer Störer mit voller Breite",

  createInitialData: () => ({}),
  childConstraints: {
    maxChildren: 1,
  },
  cellPlugins: (plugins) => [...plugins, fullWidthBarSlate],
  createInitialChildren: () => {
    return [
      times(3).map(() => ({
        plugin: fullWidthBarSlate,
        data: fullWidthBarSlate.createData(({ plugins }) => {
          return {
            children: [
              {
                plugin: plugins.wrapper.wrapWithIcon,
                data: {
                  position: "top",
                },
                children: [
                  {
                    plugin: plugins.headings.h1,
                    children: ["Platzhalter für Titel - BITTE AUSFÜLLEN"],
                  },
                  {
                    plugin: plugins.paragraphs.paragraph,
                    children: ["Platzhalter für Inhalt - BITTE AUSFÜLLEN"],
                  },
                ],
              },
            ],
          };
        }),
      })),
    ];
  },
};
export default fullWidthBox;
