import { useQuery } from "@apollo/client";
import type { CellPlugin } from "@react-page/editor";
import gql from "graphql-tag";
import { times } from "lodash-es";
import React from "react";
import styled from "styled-components";
import { selectFont } from "../../../../utils/themeUtils";
import ScrollabelGallery from "../../../layout/components/ScrollableGallery";
import BlogTeaser from "../../../teaser/components/BlogTeaser";
import { pluginBaseStyle } from "../../utils/pluginBaseStyle";
import type {
  GetBlogTeasers,
  GetBlogTeasersVariables,
} from "./types/GetBlogTeasers";

const QUERY = gql`
  query GetBlogTeasers($limit: Int) {
    blog(limit: $limit) {
      id
      title
      intro
      url
      imageUrl
      publishedAt
    }
  }
`;

const Base = styled.div`
  ${pluginBaseStyle}
`;

const Title = styled.h3`
  ${selectFont("contentPluginTitle")};
  margin-bottom: 20px;
`;

const nbTeasers = 4;

const Renderer = ({ data }) => {
  const { data: blogData } = useQuery<GetBlogTeasers, GetBlogTeasersVariables>(
    QUERY,
    {
      variables: { limit: nbTeasers },
    },
  );
  const posts = blogData?.blog;

  return (
    <Base>
      <Title>{data.title}</Title>
      <ScrollabelGallery widthInColumns={3}>
        {times(nbTeasers).map((i) => (
          <BlogTeaser key={i} data={posts?.[i]} />
        ))}
      </ScrollabelGallery>
    </Base>
  );
};

const plugin: CellPlugin<{
  title: string;
}> = {
  Renderer,
  id: "blogTeaser",
  version: 1,
  // IconComponent: ,
  title: "Blog Teaser",

  description: "Blog Teaser",

  controls: {
    columnCount: 1,
    type: "autoform",
    schema: {
      required: ["title"],
      properties: {
        title: {
          type: "string",
          default: "Blog",
        },
      },
    },
  },
};
export default plugin;
