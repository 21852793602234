import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";
import rootIntro, { rootIntroSlate } from "../plugins/rootIntro";

const rootPageIntroConfig: EditorProps = {
  childConstraints: {
    maxChildren: 1,
  },
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [rootIntroSlate, rootIntro],
};

export default rootPageIntroConfig;
