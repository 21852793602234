import React from "react";
import styled from "styled-components";
import { pluginBaseStyle } from "../../utils/pluginBaseStyle";
import slate from "@react-page/plugins-slate";
import slatePlugins from "../slatePlugins";
import type { CellPlugin } from "@react-page/editor";

export const spacingContainerSlate = slate((def) => ({
  ...def,
  id: `${def.id}/spacingContainer`,

  plugins: slatePlugins,
}));

const Base = styled.div`
  ${pluginBaseStyle};
`;

const Renderer = ({ children = null }) => {
  return <Base>{children}</Base>;
};
const plugin: CellPlugin = {
  Renderer,
  id: "spacingContainer",
  version: 1,
  // IconComponent: ,
  title: "Spacing Container",
  description: "Generic container for spacing purposes",
  cellPlugins: (plugins) => [
    ...plugins.filter((p) => p.id !== "spacingContainer"),
    spacingContainerSlate,
  ],
  createInitialChildren: () => {
    return [
      [
        {
          plugin: spacingContainerSlate,
          data: spacingContainerSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: [
                    "Place elements below, then remove this placeholder",
                  ],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
