import { useQuery } from "@apollo/client";
import type { CellPlugin } from "@react-page/editor";
import gql from "graphql-tag";
import dynamic from "next/dynamic";
import React from "react";

import type { GetTip, GetTipVariables } from "./types/GetTip";

const ContentPageTip = dynamic({
  loader: () => import("../../../tips/components/ContentPageTip"),
  ssr: true,
});

type TipType = typeof ContentPageTip;
const TipIdSelector = dynamic({
  loader: () => import("./TipIdSelector"),
  ssr: false,
});

const GET_TIP = gql`
  query GetTip($id: ID!) {
    tip(id: $id) {
      id
      number
      title
      introText
      introImage {
        thumbUrl(width: 500)
      }
      hasContent
    }
  }
`;

export const makeTipPlugin = (
  Tipcomponent: TipType,
): CellPlugin<{
  tipId: string;
}> => ({
  Renderer: ({ readOnly, data: { tipId } }) => {
    const { data } = useQuery<GetTip, GetTipVariables>(GET_TIP, {
      variables: { id: tipId },
      skip: !tipId,
    });
    const tip = data?.tip;
    if (!tip) {
      return null;
    }
    return <Tipcomponent {...tip} />;
  },
  id: "vps/tip",
  version: 1,

  title: "Veloplus Tip",
  isInlineable: false,
  description: "Veloplus Tip",

  controls: {
    type: "autoform",
    schema: {
      required: ["tipId"],
      properties: {
        tipId: {
          type: "string",
          uniforms: {
            component: TipIdSelector,
          },
        },
      },
    },
  },
});
const plugin = makeTipPlugin(ContentPageTip);
export default plugin;
