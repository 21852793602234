import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import sizes from "../../../../../config/theme/sizes";
import { browserScrollToWithOffset } from "../../../../../lib/browser";
import type { StyledLinkType } from "../../../components/StyledLink";
import StyledLink, { styledLinkEnum } from "../../../components/StyledLink";
const Icon = dynamic({
  loader: () => import("../../../../icons/components/LinkAnchorIcon"),
});

const anchorLink = pluginFactories.createComponentPlugin<{
  type: StyledLinkType;
  id?: string;
  anchor: string;
}>({
  icon: <Icon />,
  type: "VPS/SAME_PAGE_ANCHOR_LINK",
  object: "mark",
  label: "Anchor link",
  addHoverButton: true,
  addToolbarButton: true,
  controls: {
    type: "autoform",
    schema: {
      required: ["anchor", "type"],
      type: "object",
      properties: {
        type: {
          type: "string",
          enum: styledLinkEnum,
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
        anchor: {
          type: "string",
        },
      },
    },
  },

  Component: (props) => (
    <StyledLink
      type={props.type ?? "inline"}
      id={props.id}
      href={`#${props.anchor}`}
      onClick={(e) => {
        // do smooth scroll without the need to define it globally
        e.preventDefault();

        browserScrollToWithOffset(`a[name=${props.anchor}], #${props.anchor}`, {
          offset: sizes.anchorLinkOffset,
        });

        history.replaceState(undefined, undefined, `#${props.anchor}`);
      }}
    >
      {props.children}
    </StyledLink>
  ),
});

export default anchorLink;
