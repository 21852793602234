import { useRouter } from "next/router";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";
import { usePreviousRoute } from "../../../lib/PreviousRouteProvider";

import Button from "../../layout/components/Button";

const Base = styled.div`
  margin-top: 30px;
`;

export const StyledButton = styled(Button).attrs({
  variant: "secondary",
  size: "small",
})``;

export interface BackToOverviewButtonProps {
  style?: React.CSSProperties;
  className?: string;
}

const BackToOverviewButton: React.FC<BackToOverviewButtonProps> = ({
  style,
  className,
}) => {
  const { asPath: previousPath } = usePreviousRoute();
  const { asPath: currentPath, push } = useRouter();
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    const currentPathSegments = currentPath.split("/");
    currentPathSegments.pop();
    const overviewUrl = currentPathSegments.join("/");
    const isComingFromOverviewPage = previousPath?.startsWith(overviewUrl);

    if (isComingFromOverviewPage) {
      window.history.back();
    } else {
      push(overviewUrl);
    }
  }, [previousPath, currentPath, push]);

  return (
    <Base style={style} className={className}>
      <StyledButton onClick={onClick}>{t("backToOverview")}</StyledButton>
    </Base>
  );
};

export default BackToOverviewButton;
