import type { CellPlugin, EditorProps } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";
import usePagePaths from "../hooks/usePagePaths";
import fullWidthBox from "../plugins/fullWidthBar";
import htmlPlugin from "../plugins/html";
import image from "../plugins/image";
import imageGallery from "../plugins/imageGallery";
import infoBar from "../plugins/infoBar";
import infoBox from "../plugins/infoBox";
import productSlider from "../plugins/productSlider";
import quoteBlock from "../plugins/quoteBlock";
import slatePlugins from "../plugins/slatePlugins";
import spacingContainer from "../plugins/spacingContainer";
import teaserWithBackgroundImage from "../plugins/teaserWithBackgroundImage";

import youtubeVideo from "../plugins/youtubeVideo";

const OpeningHours = dynamic({
  loader: () => import("../../stores/components/OpeningHours"),
});
const StoreDetailBooking = dynamic({
  loader: () => import("../../stores/components/StoreDetailBooking"),
});
const StoreDetailMap = dynamic({
  loader: () => import("../../stores/components/StoreDetailMap"),
});

export const defaultSlate = slate((def) => ({
  ...def,

  plugins: slatePlugins,
}));

const useStoreIdFromCurrentRoute = () => {
  const router = useRouter();
  const { subPageId: storeId } = usePagePaths();
  if (storeId) return storeId;
  // special case: in admin, we have to derive the id differently
  if (router.route === "/admin") {
    return document.location.hash.match(/#\/Store\/([^/]+)/)?.[1];
  }
};

const storeDetailOpeningHours: CellPlugin = {
  id: "store-detail-opening-hours",
  title: "Öffnungszeiten",
  version: 1,
  Renderer: () => {
    const storeId = useStoreIdFromCurrentRoute();
    return <OpeningHours storeId={storeId} />;
  },
};

const storeDetailMap: CellPlugin = {
  id: "store-detail-map",
  title: "Karte",
  version: 1,
  Renderer: () => {
    const storeId = useStoreIdFromCurrentRoute();
    return <StoreDetailMap storeId={storeId} />;
  },
};

const storeDetailBooking: CellPlugin<{ title: string }> = {
  id: "store-detail-booking",
  title: "Buchungswidget",
  version: 1,
  Renderer: (data) => {
    const storeId = useStoreIdFromCurrentRoute();
    return <StoreDetailBooking storeId={storeId} {...data} />;
  },
  controls: {
    type: "autoform",
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: "string",
          default: "",
        },
      },
    },
  },
};

const storeContentAreaConfig: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [
    defaultSlate,
    storeDetailOpeningHours,
    storeDetailMap,
    storeDetailBooking,
    productSlider,
    image,
    imageGallery,
    youtubeVideo,
    htmlPlugin,

    infoBox,
    infoBar,
    fullWidthBox,
    teaserWithBackgroundImage,
    quoteBlock,
    spacingContainer,
  ],
};

export default storeContentAreaConfig;
