import slate from "@react-page/plugins-slate";
import styled from "styled-components";
import { selectFont } from "../../../utils/themeUtils";
import bikeCategoryTeaserList from "../plugins/bikeCategoryTeaserList";
import blogTeaser from "../plugins/blogTeaser";
import featuredProducts from "../plugins/featuredProducts";
import productSlider from "../plugins/productSlider";
import brandsSlider from "../plugins/brandsSlider";
import fullWidthBox from "../plugins/fullWidthBar";
import htmlPlugin from "../plugins/html";
import image from "../plugins/image";
import imageGallery from "../plugins/imageGallery";
import infoBar from "../plugins/infoBar";
import infoBox from "../plugins/infoBox";
import quoteBlock from "../plugins/quoteBlock";
import slatePlugins from "../plugins/slatePlugins";
import teaserWithBackgroundImage from "../plugins/teaserWithBackgroundImage";
import tip from "../plugins/tip";
import youtubeVideo from "../plugins/youtubeVideo";
import spacingContainer from "../plugins/spacingContainer";
import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";

const LandingParagraph = styled.p`
  ${selectFont("textDetails")};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }
`;
export const landingSlate = slate((def) => ({
  ...def,
  plugins: {
    ...slatePlugins,
    paragraphs: {
      paragraph: def.plugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <LandingParagraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
  },
}));

const landingContentAreaConfig: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [
    landingSlate,

    bikeCategoryTeaserList,
    blogTeaser,
    featuredProducts,
    productSlider,
    brandsSlider,
    image,
    imageGallery,
    tip,
    youtubeVideo,
    htmlPlugin,

    infoBox,
    infoBar,
    fullWidthBox,
    teaserWithBackgroundImage,
    quoteBlock,
    spacingContainer,
  ],
};

export default landingContentAreaConfig;
