import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
import BrandItem from "../../categories/components/BrandItem";
import useTopBrands from "../../categories/hooks/useTopBrands";
import ALink from "../../content/components/ALink";
import ScrollabelGallery from "../../layout/components/ScrollableGallery";
import { BRAND_BASE_NAV_ITEM } from "../../navigation/utils/makeNavItemFromAssortment";
import FullWidthBox from "./FullWidthBox";
export interface BrandsProps {
  style?: React.CSSProperties;
  className?: string;
  title: string;
}

const BrandItemBox = styled.div`
  border-radius: 5px;

  background-color: ${selectColor("white")};

  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 30px;

  & > * {
    display: block;
    width: 100px;
  }
`;
const Brands: React.FC<BrandsProps> = ({ style, className, title }) => {
  const { topBrands } = useTopBrands();

  return (
    <FullWidthBox title={title}>
      <ScrollabelGallery
        widthInColumns={2}
        buttonsBackgroundColor="sand"
        style={{ marginBottom: 30 }}
      >
        {topBrands
          ?.filter((brand) => brand.assortment) // remove brands without assortment
          .map((brand) => (
            <BrandItemBox key={brand.id}>
              <BrandItem brand={brand} />
            </BrandItemBox>
          )) ?? null}
      </ScrollabelGallery>

      <Link
        href={BRAND_BASE_NAV_ITEM.href}
        as={BRAND_BASE_NAV_ITEM.path}
        passHref
      >
        <ALink>Alle Marken ansehen</ALink>
      </Link>
    </FullWidthBox>
  );
};

export default Brands;
