import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { AssortmentTextsParts } from "../../product/queries";
import type { GetTopBrands } from "./types/GetTopBrands";

const TOP_BRANDS_QUERY = gql`
  query GetTopBrands {
    topBrands {
      id
      assortment {
        _id

        texts {
          ...AssortmentTextsParts
        }
      }
      logo {
        id
        src
      }
    }
  }
  ${AssortmentTextsParts}
`;
const useTopBrands = () => {
  const { data, error, loading } = useQuery<GetTopBrands>(TOP_BRANDS_QUERY);

  return {
    topBrands: data?.topBrands,
    error,
    loading,
  };
};
export default useTopBrands;
