import slate, { slatePlugins } from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { selectSize } from "../../../../utils/themeUtils";
import Heading from "../../../layout/components/Heading";
import PageColumn from "../../../layout/components/PageColumn";
import useImage from "../../hooks/useImage";
import type { FileReference } from "../image";
import { imageFileSchemaProperty } from "../image";
import ourSlatePlugins from "../slatePlugins";
import { Paragraph } from "../slatePlugins/paragraphs";
import ContentColumn from "../../../layout/components/ContentColumn";
import { getFocalPointBackgroundPosition } from "../../utils/getFocalPointBackgroundPosition";
import type { FocalPoint } from "../../../admin/fileUpload/uploadFocalPoint";
import type { CellPlugin } from "@react-page/editor";
import Head from "next/head";

const IntroHeading = styled(Heading).attrs({ level: 1 })`
  margin-bottom: 20px;
`;

const IntroParagraph = styled(Paragraph).attrs({ font: "textLead" })`
  &:last-child {
    margin-bottom: 0;
  }
`;

export const rootIntroSlate = slate((def) => ({
  ...def,
  plugins: {
    headings: {
      h1: slatePlugins.headings.h1((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <IntroHeading {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    paragraphs: {
      paragraph: slatePlugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <IntroParagraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    ...pick(ourSlatePlugins, ["links", "emphasize", "quotes"]),
  },
}));

const Base = styled.div`
  margin-bottom: ${selectSize("introBottomMargin")}px;
`;

const TeaserImage = styled.div<{ focalPoint?: FocalPoint }>`
  position: relative;
  background-size: cover;
  background-position: center;
  height: 330px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  background-position: ${(p) => getFocalPointBackgroundPosition(p.focalPoint)};

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.15) 100%
    );

    ${mediaQueries.desktop`
      right: 50%;
    `}
  }
`;

const Renderer = ({ data, children = null }) => {
  const { file } = useImage(data.teaserImage, 2048);

  return (
    <>
      {file ? (
        <Head>
          <link rel="preload" as="image" href={file.url}></link>
        </Head>
      ) : null}
      <Base>
        <TeaserImage
          focalPoint={file?.focalPoint}
          style={{
            backgroundImage: file ? `url("${file.url}")` : undefined,
          }}
        />
        <PageColumn>
          <ContentColumn>{children}</ContentColumn>
        </PageColumn>
      </Base>
    </>
  );
};

const plugin: CellPlugin<{ teaserImage?: FileReference }> = {
  Renderer,
  id: "rootIntro",
  version: 1,
  // IconComponent: ,
  title: "Root Page Intro",
  description: "Root Page Intro",
  controls: {
    type: "autoform",
    schema: {
      properties: {
        teaserImage: imageFileSchemaProperty({ label: "Image" }),
      },
      required: [],
    },
  },
  childConstraints: {
    maxChildren: 1,
  },
  createInitialChildren: () => {
    return [
      [
        {
          plugin: rootIntroSlate,
          data: rootIntroSlate.createData(({ plugins }) => {
            return {
              children: [
                {
                  plugin: plugins.headings.h1,
                  children: ["Platzhalter für Titel - BITTE AUSFÜLLEN"],
                },
                {
                  plugin: plugins.paragraphs.paragraph,
                  children: ["Platzhalter für Inhalt - BITTE AUSFÜLLEN"],
                },
              ],
            };
          }),
        },
      ],
    ];
  },
};

export default plugin;
