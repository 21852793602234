import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import CategoryLink from "../../../components/CategoryLink";
import type { StyledLinkType } from "../../../components/StyledLink";
import { styledLinkEnum } from "../../../components/StyledLink";

const AssortmentIdSelector = dynamic({
  loader: () =>
    import("../../../../admin/editorAdminFields/AssortmentIdSelector"),
  ssr: false,
});

const LinkIcon = dynamic({
  loader: () => import("../../../../icons/components/LinkCategoryIcon"),
});

export default pluginFactories.createComponentPlugin<{
  assortmentId: string;
  type: StyledLinkType;
  additionalQuery?: string;
  id?: string;
}>({
  icon: <LinkIcon />,
  type: "VPS/CATEGORY_LINK",
  object: "mark",
  label: "Category link",
  addHoverButton: true,
  addToolbarButton: true,
  controls: {
    type: "autoform",
    schema: {
      required: ["assortmentId", "type"],
      type: "object",
      properties: {
        assortmentId: {
          type: "string",
          uniforms: {
            component: AssortmentIdSelector,
          },
        },
        type: {
          type: "string",
          enum: styledLinkEnum,
        },
        additionalQuery: {
          type: "string",
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
      },
    },
  },
  getInitialData: () => ({
    type: "inline",
    assortmentId: "asdf",
  }),
  Component: (props) => (
    <CategoryLink
      type={props.type ?? "inline"}
      assortmentId={props.assortmentId}
      additionalQuery={props.additionalQuery}
      id={props.id}
    >
      {props.children}
    </CategoryLink>
  ),
});
