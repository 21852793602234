import type { CSSProperties } from "react";
import React, { useState } from "react";
import useMeasure from "react-use/lib/useMeasure";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { iff } from "../../../utils/themeUtils";
import MinusIcon from "../../icons/components/MinusIcon";
import PlusIcon from "../../icons/components/PlusIcon";
import Box from "../../layout/components/Box";
import Button from "../../layout/components/Button";

const Base = styled(Box)`
  overflow: hidden;
`;
const Inner = styled.div``;

const Content = styled.div<{
  toggleOnMobile?: boolean;
  toggleOnDesktop?: boolean;
  height: CSSProperties["height"];
  open: boolean;
}>`
  overflow: hidden;

  max-height: ${(p) => (p.open || !p.toggleOnMobile ? p.height : 0)}px;
  ${iff((p) => p.toggleOnMobile)`
   transition: max-height 0.6s;
   `.else`
      transition: none;
    `}

  ${mediaQueries.desktop`
    ${iff((p) => p.toggleOnDeskto)`
      transition: max-height 0.6s;
    `.else`
      transition: none;
    `}
    max-height: ${(p) => (p.open || !p.toggleOnDesktop ? p.height : 0)}px;
  `}

  ${Inner} {
    padding: ${(p) => (p.toggleOnMobile ? "30px 0px" : "0px")};
    ${mediaQueries.desktop`
    padding: ${(p) => (p.toggleOnDesktop ? "30px 0px" : "0px")};
    `}
  }
`;

const TheButton = styled(Button)<{
  toggleOnMobile?: boolean;
  toggleOnDesktop?: boolean;
}>`
  width: 100%;
  display: ${(p) => (p.toggleOnMobile ? null : "none")};
  ${mediaQueries.desktop`
  display: ${(p) => (p.toggleOnDesktop ? null : "none")};
  `}
`;

export interface ToggleBoxProps {
  style?: React.CSSProperties;
  className?: string;
  toggleOnMobile?: boolean;
  toggleOnDesktop?: boolean;
  titleCollapsed?: string;
  titleOpen?: string;
}

const ToggleBox: React.FC<ToggleBoxProps> = ({
  children,
  style,
  className,
  toggleOnDesktop,
  toggleOnMobile,
  titleCollapsed = "Alles zum Thema",
  titleOpen = "Alles zum Thema",
}) => {
  const [open, setOpen] = useState(false);
  const [ref, { height }] = useMeasure();

  return (
    <Base style={style} className={className}>
      <TheButton
        size="small"
        variant="sand"
        toggleOnDesktop={toggleOnDesktop}
        toggleOnMobile={toggleOnMobile}
        onClick={() => setOpen(!open)}
        startIcon={
          open ? (
            <MinusIcon inverted style={{ width: 15, height: 15 }} />
          ) : (
            <PlusIcon inverted style={{ width: 15, height: 15 }} />
          )
        }
      >
        {open ? titleOpen : titleCollapsed}
      </TheButton>
      <Content
        height={height || "auto"}
        open={open}
        toggleOnDesktop={toggleOnDesktop}
        toggleOnMobile={toggleOnMobile}
      >
        <div ref={ref}>
          <Inner>{children}</Inner>
        </div>
      </Content>
    </Base>
  );
};

export default ToggleBox;
