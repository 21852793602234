import type { CellPlugin, EditorProps } from "@react-page/editor";
import slate from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import Head from "next/head";
import React from "react";
import styled from "styled-components";
import Seo from "../components/Seo";
import useImage from "../hooks/useImage";
import type { FileReference } from "../plugins/image";
import { imageFileSchemaProperty } from "../plugins/image";
import ourSlatePlugins from "../plugins/slatePlugins";
import { Paragraph } from "../plugins/slatePlugins/paragraphs";
import youtubePlugin from "../plugins/youtubeVideo";
import { getFocalPointBackgroundPosition } from "../utils/getFocalPointBackgroundPosition";

import { SEMI_BOLD } from "../../../config/theme/fonts";
import { selectFont } from "../../../utils/themeUtils";
import CategoryIntroTip from "../../tips/components/CategoryIntroTip";
import ALink from "../components/ALink";
import useFile from "../hooks/useFile";
import { makeTipPlugin } from "../plugins/tip";
import { toggleBox } from "../plugins/togglebox";

const tip = makeTipPlugin(CategoryIntroTip);

const Image = styled.img<{
  focalPoint?: React.CSSProperties["objectPosition"];
}>`
  object-fit: cover;
  object-position: ${({ focalPoint }) => focalPoint ?? "center"};
  border-radius: 5px;
  height: 312px;
`;

const IntroParagraph = styled(Paragraph).attrs({ font: "textContent" })`
  ${selectFont("textDetails")};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }
`;

const BlockTitle = styled.h3`
  ${selectFont("textDetailsBold")};
`;

const BlockParagraph = styled(Paragraph).attrs({ font: "textDetails" })`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const image: CellPlugin<{
  fileRef: FileReference;
  alt?: string;
}> = {
  Renderer: (props) => {
    const image = useImage(props.data.fileRef, 1440);
    return (
      <>
        <Seo
          openGraph={{
            images: image?.file?.url
              ? [
                  {
                    url: image?.file?.url,
                  },
                ]
              : null,
          }}
        />
        {image?.file?.url ? (
          <Head>
            <link rel="preload" as="image" href={image?.file?.url}></link>
          </Head>
        ) : null}
        <Image
          alt={props.data.alt}
          src={image.file?.url}
          focalPoint={getFocalPointBackgroundPosition(image.file?.focalPoint)}
        />
      </>
    );
  },
  id: "ory/editor/core/content/image",
  version: 1,
  // IconComponent: ,
  title: "image block",
  description: "image block",
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        fileRef: imageFileSchemaProperty({ label: "image" }),
        alt: {
          type: "string",
          uniforms: {
            multiline: true,
          },
        },
      },
    },
  },
};

const textBlockSlate: CellPlugin<any> = slate((def) => ({
  ...def,
  plugins: {
    headings: {
      h3: ourSlatePlugins.headings.h3((d) => ({
        ...d,
        Component: BlockTitle,
      })),
    },
    paragraphs: {
      paragraph: ourSlatePlugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: BlockParagraph,
      })),
    },
    ...pick(ourSlatePlugins, ["links", "emphasize", "quotes"]),
  },
}));

const TextWrapper = styled.div`
  ${ALink} {
    font-weight: ${SEMI_BOLD};
  }
`;
const innerTextBlock: CellPlugin<{
  icon: FileReference;
}> = {
  ...textBlockSlate,
  cellStyle: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  Renderer: (props) => {
    const { file } = useFile(props.data.icon);
    return (
      <TextWrapper>
        {file ? (
          <img
            src={file?.src}
            style={{
              height: 48,
              marginBottom: 12,
            }}
          />
        ) : null}
        <textBlockSlate.Renderer {...props}></textBlockSlate.Renderer>
      </TextWrapper>
    );
  },
  controls: [
    {
      title: "text",
      controls: textBlockSlate.controls as any,
    },
    {
      title: "Icon",
      controls: {
        type: "autoform",
        schema: {
          properties: {
            icon: imageFileSchemaProperty({ label: "Icon" }),
          },
        },
      },
    },
  ],
};

const boxWithDefaults: CellPlugin = {
  ...toggleBox,
  cellPlugins: [innerTextBlock, image, tip, youtubePlugin],
  createInitialChildren: () => [
    {
      cells: [
        {
          plugin: image.id,
          size: 6,
        },
        {
          plugin: innerTextBlock.id,
          size: 3,
        },
        {
          plugin: tip.id,
          size: 3,
        },
      ],
    },
  ],
};

const introText = slate((def) => ({
  ...def,
  plugins: {
    paragraphs: {
      paragraph: ourSlatePlugins.paragraphs.paragraph((d) => ({
        ...d,
        Component: ({ style, align, ...props }) => (
          <IntroParagraph {...props} style={{ textAlign: align, ...style }} />
        ),
      })),
    },
    ...pick(ourSlatePlugins, ["links", "emphasize", "quotes"]),
  },
}));

const cellPlugins: CellPlugin[] = [
  boxWithDefaults,
  image,
  introText,
  youtubePlugin,
];

export const introConfig: EditorProps = {
  cellSpacing: {
    x: 20,
    y: 30,
  },
  childConstraints: {
    maxChildren: 2,
  },
  cellPlugins,
};
