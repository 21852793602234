import React, { useContext } from "react";
import { extractSubPageId } from "../../core/lib/urlUtils";

const Context = React.createContext({
  fullPath: null,
  pagePath: null,
});

export const PagePathsProvider = Context.Provider;
const usePagePaths = () => {
  const { fullPath, pagePath } = useContext(Context);

  const subPath = fullPath?.replace(pagePath, "") ?? null;
  const subPageId = extractSubPageId(subPath);
  return {
    fullPath,
    pagePath,
    subPath,
    subPageId,
  };
};

export default usePagePaths;
