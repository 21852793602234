import type { CellPlugin } from "@react-page/editor";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import { usePagePath } from "../../components/PageLink";

const TipAllSeoList = dynamic({
  loader: () => import("../../../tips/components/TipAllSeoList"),
  ssr: true,
});

const PageIdSelector = dynamic({
  loader: () => import("../../../admin/editorAdminFields/PageIdSelector"),
  ssr: false,
});

const plugin: CellPlugin<{
  tipDetailPageId?: string;
}> = {
  Renderer: (props) => {
    const router = useRouter();

    const parentPath = router.asPath.slice(0, router.asPath.lastIndexOf("/"));

    const { data } = usePagePath(props.data.tipDetailPageId);
    const path = props.data.tipDetailPageId ? data?.page?.path : parentPath;

    return <TipAllSeoList tipDetailBasePath={path} />;
  },
  id: "vps/tip list",
  version: 1,

  title: "Veloplus Tip list",
  isInlineable: false,
  description: "Shows all velo plus tips as a list",
  controls: {
    type: "autoform",
    schema: {
      properties: {
        tipDetailPageId: {
          type: "string",
          title: "Tip seite (für detail). Leer --> parent page",
          uniforms: {
            component: PageIdSelector,
            additionalWhere: {
              pageTemplate: "TIPS",
            },
          },
        },
      },
    },
  },
};

export default plugin;
