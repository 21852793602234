import slate, { slatePlugins } from "@react-page/plugins-slate";
import { pick } from "lodash-es";
import React from "react";
import styled from "styled-components";
import footerPageLink from "../slatePlugins/links/footerPageLink";
import footerExternalLink from "../slatePlugins/links/footerExternalLink";
import { iff, selectColor, selectFont } from "../../../../utils/themeUtils";
import mediaQueries from "../../../../utils/mediaQueries";
import type { CellPlugin } from "@react-page/editor";

export const footerSlate = slate((def) => ({
  ...def,
  id: `${def.id}/footer`,
  hideInMenu: true,
  plugins: {
    links: {
      footerPageLink,
      footerExternalLink,
    },
    ...pick(slatePlugins, ["paragraphs"]),
  },
}));

export const FooterBase = styled.div<{
  readOnly: boolean;
  showOnMobile: boolean;
}>`
  display: none;
  margin-top: 20px;

  ${iff((p) => p.showOnMobile)`
    display: block;
  `}

  ${iff((p) => !p.readOnly)`
    a {
      color: ${selectColor("black")};

      &:hover {
        color: ${selectColor("black")};
      }
    }
  `}

  ${mediaQueries.desktop`
    display: block;
  `}
`;

export const FooterTitle = styled.div`
  margin-bottom: 18px;
  ${selectFont("footerTitle")}

  ${mediaQueries.desktop`
    margin-bottom: 9px;
  `}
`;

const Renderer = ({ readOnly, data, children = null }) => (
  <FooterBase readOnly={readOnly} showOnMobile={data.showOnMobile}>
    <FooterTitle>{data.title}</FooterTitle>
    {children}
  </FooterBase>
);

const plugin: CellPlugin<{
  showOnMobile: boolean;
  title: string;
}> = {
  Renderer,
  id: "footerColumn",
  version: 1,
  // IconComponent: ,
  title: "Footer Kolonne",
  description: "Footer Kolonne bestehend aus Titel und einer Linkliste",
  controls: {
    type: "autoform",
    schema: {
      required: ["title"],
      properties: {
        showOnMobile: {
          type: "boolean",
        },
        title: {
          type: "string",
        },
      },
    },
  },
  createInitialState: () => ({
    showOnMobile: false,
    title: "",
  }),
  createInitialChildren: () => {
    return [
      [
        {
          plugin: footerSlate,
          data: footerSlate.createData(),
        },
      ],
    ];
  },
};
export default plugin;
