import { Data } from "@react-google-maps/api";
import type { CellPlugin, JsonSchemaProperty } from "@react-page/editor";
import dynamic from "next/dynamic";
import React from "react";
import Rectangle from "react-rectangle";
import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import {
  iff,
  selectColor,
  selectFont,
  selectSize,
} from "../../../../utils/themeUtils";
import FullScreenWidth from "../../../layout/components/FullScreenWidth";
import useImage from "../../hooks/useImage";

const ImagePlaceholder = dynamic({
  loader: () => import("@material-ui/icons/Landscape"),
});

const Image = styled.img<{ smallMobileVersion: boolean }>`
  width: 100%;
  display: block;
  ${iff((p) => p.smallMobileVersion)`
     max-height: 150px;
     width: auto;
    `}
  ${mediaQueries.desktop`
       width: 100%;
       height: auto;
       max-height: none;
    `}
`;

const Caption = styled.p`
  margin-top: 12px;
  ${selectFont("imageCaption")};
`;
const Placeholder = styled(Rectangle).attrs(() => ({
  aspectRatio: 4 / 3,
  children: <ImagePlaceholder />,
}))`
  background-color: ${selectColor("greyLight")};
  & svg {
    height: 100% !important;
    width: 100% !important;
  }
`;
const ImageUploadField = dynamic({
  loader: () => import("./ImageUploadField"),
  ssr: false,
});

const ImageWrapper = styled.div<{ addTopMargin: boolean }>`
  margin-bottom: 40px;

  ${mediaQueries.desktop`
      ${iff((p) => p.addTopMargin)`
        margin-top: ${selectSize("pluginOptionalTopMargin")}px;
      `}
    `}
`;

type PluginProps = {
  fileRef: FileReference;
  fullScreenWidth?: boolean;
  addTopMargin?: boolean;
  caption?: string;
  smallMobileVersion?: boolean;
  alt?: string;
};

const Renderer = ({
  data: {
    fileRef,
    caption = null,
    fullScreenWidth = false,
    addTopMargin = false,
    smallMobileVersion = false,
    alt = "",
  },
}: {
  data: PluginProps;
}) => {
  const { file } = useImage(fileRef, fullScreenWidth ? 2048 : 1024);
  const content = file ? (
    <Image
      loading="lazy"
      smallMobileVersion={smallMobileVersion}
      src={file.url}
      alt={alt}
    />
  ) : (
    <Placeholder />
  );
  return (
    <ImageWrapper addTopMargin={addTopMargin}>
      {fullScreenWidth ? <FullScreenWidth>{content}</FullScreenWidth> : content}
      {caption ? <Caption>{caption}</Caption> : null}
    </ImageWrapper>
  );
};

export type FileReference = {
  bucket: string;
  fileId: string;
};

export const imageFileSchemaProperty = ({
  label,
}): JsonSchemaProperty<FileReference, unknown> => ({
  type: "object",
  uniforms: {
    component: ImageUploadField,
    label,
  },
  properties: {
    fileId: {
      type: "string",
    },
    bucket: {
      type: "string",
    },
  },
  required: [],
});

const plugin: CellPlugin<PluginProps> = {
  Renderer,
  id: "ory/editor/core/content/image",
  version: 1,
  // IconComponent: ,
  title: "image block",
  isInlineable: true,
  description: "image block",
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        fileRef: imageFileSchemaProperty({ label: "image" }),

        fullScreenWidth: {
          type: "boolean",
        },

        smallMobileVersion: {
          type: "boolean",
        },

        addTopMargin: {
          type: "boolean",
        },

        caption: {
          type: "string",
          uniforms: {
            multiline: true,
          },
        },
        alt: {
          type: "string",
          uniforms: {
            multiline: true,
          },
        },
      },
    },
  },
};
export default plugin;
