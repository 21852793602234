import type { Ref } from "react";
import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

const Element = styled.div<{ offset: number; scrollbarWidth: number }>`
  width: calc(100vw - ${(p) => p.scrollbarWidth}px);
  position: relative;
  margin-left: calc(-50vw - ${(p) => p.offset}px);
  left: 50%;
  overflow: hidden;
`;

export interface FullScreenWidthProps {
  style?: React.CSSProperties;
  className?: string;
}

const getOffset = (el: HTMLDivElement) => {
  const rect = el.getBoundingClientRect();

  const { left, right } = rect;
  const fromRight = window.innerWidth - right;
  // when left is == fromRight, offset is 0
  return (left - fromRight) / 2;
};

/**
 * returns the offset by which the element is not eqully centered.
 * If it is equally centered, offset will be 0
 */
const useOffset = (): [Ref<HTMLDivElement>, number, number] => {
  const [offset, setOffset] = useState(0);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const ref = useRef<HTMLDivElement>();
  if (process.browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      let handle;
      if (ref.current) {
        const measure = () =>
          (handle = window.requestAnimationFrame(() => {
            if (ref.current) {
              setOffset(getOffset(ref.current));
            }
            setScrollbarWidth(
              window.innerWidth - document.documentElement.clientWidth,
            );
          }));
        measure();
        window.addEventListener("resize", measure);

        return () => {
          window.cancelAnimationFrame?.(handle);
          window.removeEventListener("resize", measure);
        };
      }
    }, [ref]);
  }

  return [ref, offset, scrollbarWidth];
};
const FullScreenWidth: React.FC<FullScreenWidthProps> = ({
  style,
  className,
  children,
}) => {
  const [ref, offset, scrollbarWidth] = useOffset();

  return (
    <>
      <div ref={ref} />
      <Element
        style={style}
        className={className}
        offset={offset}
        scrollbarWidth={scrollbarWidth}
      >
        {children}
      </Element>
    </>
  );
};

export default FullScreenWidth;
