import type { ReactElement } from "react";
import React from "react";
import styled from "styled-components";

const Label = styled.span`
  display: inline-flex;
`;

const Base = styled.label`
  display: inline-flex;
  align-items: flex-start;

  cursor: pointer;
`;

const ControlWrapper = styled.span`
  display: inline-flex;
  align-items: center;

  margin-right: 7px;
`;

export interface ControlLabelProps {
  control?: ReactElement;
  label: any;
  style?: React.CSSProperties;
  className?: string;
  labelStyle?: React.CSSProperties;
  onClick?: (e: any) => any;
}

const ControlLabel: React.FC<ControlLabelProps> = ({
  control,
  label,
  labelStyle,
  style,
  className,
  onClick,
}) => (
  <Base style={style} className={className} onClick={onClick}>
    {control ? <ControlWrapper>{control}</ControlWrapper> : null}
    <Label className="label" style={labelStyle}>
      {label}
    </Label>
  </Base>
);

export default ControlLabel;
