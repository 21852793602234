import { css } from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { selectSize } from "../../../utils/themeUtils";

export const pluginBaseStyle = css`
  margin-bottom: ${selectSize("pluginMobileMargin")}px;

  ${mediaQueries.desktop`
    margin-bottom: ${selectSize("pluginDesktopMargin")}px;
  `}
`;

export const pluginTopMargin = css`
  margin-top: ${selectSize("pluginMobileMargin")}px;

  ${mediaQueries.desktop`
    margin-top: ${selectSize("pluginDesktopMargin")}px;
  `}
`;
