import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import Heading from "../../layout/components/Heading";

const SectionHeading = styled(Heading).attrs({ level: 4 })`
  margin-bottom: 10px;
  ${mediaQueries.desktop`
    margin-bottom: 30px;
`}
`;
export default SectionHeading;
