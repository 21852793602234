import React from "react";
import styled from "styled-components";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";
import SectionHeading from "../../content/components/SectionHeading";
import { getFocalPointBackgroundPosition } from "../../content/utils/getFocalPointBackgroundPosition";
import { pluginBaseStyle } from "../../content/utils/pluginBaseStyle";
import ContentWrapper from "./ContentWrapper";
import type { TeaserWithImageProps } from "./TeaserWithImage";
import { minHeightStyle } from "./TeaserWithImage";

const Base = styled.div`
  ${pluginBaseStyle}
`;

const Teaser = styled.div`
  border-radius: 5px;
  overflow: hidden;
  padding: 30px;
  display: flex;
  background-size: cover;
  background-position: center;

  background-color: ${selectColor("sand")};
  ${minHeightStyle};
  p {
    ${selectFont("textDetails")};
  }
`;

const ContentWrapperStyled = styled(ContentWrapper)<{
  maxContentWidth: number;
}>`
  border-radius: 5px;
  margin-top: auto;
  background-color: ${selectColor("white")};
  ${iff((p) => p.maxContentWidth)`
    max-width: ${(p) => p.maxContentWidth}px;
  `};
`;

export type TeaserWithBackgroundImageProps = TeaserWithImageProps & {
  maxContentWidth?: number;
};
const TeaserWithBackgroundImage: React.FC<TeaserWithBackgroundImageProps> = ({
  style,
  className,
  imageSrc,
  labelImageSrc,
  authorImageSrc,
  children,
  maxContentWidth,
  heading = "",
  focalPoint,
  addHeaderSpacing = false,
}) => {
  return (
    <Base style={style} className={className}>
      {heading || addHeaderSpacing ? (
        <SectionHeading>{heading}</SectionHeading>
      ) : null}
      <Teaser
        style={{
          backgroundImage: imageSrc ? `url("${imageSrc}")` : null,
          backgroundPosition: getFocalPointBackgroundPosition(focalPoint),
        }}
      >
        <ContentWrapperStyled
          maxContentWidth={maxContentWidth}
          labelImageSrc={labelImageSrc}
          authorImageSrc={authorImageSrc}
        >
          {children}
        </ContentWrapperStyled>
      </Teaser>
    </Base>
  );
};

export default TeaserWithBackgroundImage;
