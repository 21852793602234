import type { CellPlugin } from "@react-page/editor";
import dynamic from "next/dynamic";

export type ProductSliderDataItem = {
  title: string;
  productIds: string[];
  link: {
    href: string;
    label: string;
  };
};

const Renderer = dynamic({
  loader: () => import("./Renderer"),
});
const plugin: CellPlugin<ProductSliderDataItem> = {
  Renderer: (data) => <Renderer {...data} />,
  id: "productSlider",
  version: 1,
  // IconComponent: ,
  title: "Product Slider",

  description: "Product Slider",

  controls: {
    type: "autoform",
    columnCount: 1,
    schema: {
      required: ["title", "productIds"],
      properties: {
        title: {
          type: "string",
          default: "Produkte",
        },
        link: {
          type: "object",
          properties: {
            label: {
              type: "string",
            },
            href: {
              type: "string",
              title: "URL",
            },
          },
        },
        productIds: {
          type: "array",

          items: {
            type: "string",
          },
        },
      },
    },
  },
};

export default plugin;
