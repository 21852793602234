import type { CellPlugin } from "@react-page/editor";
import React from "react";

const Renderer = ({ readOnly, data: { html } }) => {
  return (
    <>
      {!readOnly ? <button type="button">edit html</button> : null}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
};

const plugin: CellPlugin<{
  html: string;
}> = {
  Renderer,
  id: "html-plugin",
  version: 1,
  // IconComponent: ,
  title: "html block",
  isInlineable: true,
  description: "html block",
  controls: {
    type: "autoform",
    schema: {
      required: ["html"],
      properties: {
        html: {
          type: "string",
          uniforms: {
            multiline: true,
            rows: 10,
            style: {
              width: 512,
            },
          },
        },
      },
    },
  },
};
export default plugin;
