import React from "react";
import styled from "styled-components";
import useImage from "../../hooks/useImage";
import type { FileReference } from "../image";

export interface TrustIconProps {
  style?: React.CSSProperties;
  className?: string;
  href: string;
  imageFileRef: FileReference;
}

const Base = styled.a.attrs({ target: "_blank" })`
  width: 62px;
  height: auto;
  margin-right: 15px;

  img {
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const TrustIcon: React.FC<TrustIconProps> = ({
  style,
  className,
  href,
  imageFileRef,
}) => {
  const { file } = useImage(imageFileRef, 100);

  return (
    <Base style={style} className={className} href={href}>
      <img src={file?.url} />
    </Base>
  );
};

export default TrustIcon;
