import { pluginFactories } from "@react-page/plugins-slate";
import dynamic from "next/dynamic";
import React from "react";
import { SHOP_PATH } from "../../../../../config/locations";
import StoreLink from "../../../components/StoreLink";
import type { StyledLinkType } from "../../../components/StyledLink";
import { styledLinkEnum } from "../../../components/StyledLink";

const StoreIdSelector = dynamic({
  loader: () => import("../../../../admin/editorAdminFields/StoreIdSelector"),
  ssr: false,
});

const Icon = dynamic({
  loader: () => import("../../../../icons/components/LinkStoreIcon"),
});

export default pluginFactories.createComponentPlugin<{
  storeId: string;
  type: StyledLinkType;
  id?: string;
  anchor?: string;
  showShopMenu?: boolean;
}>({
  icon: <Icon />,
  type: "VPS/STORE_LINK",
  object: "mark",
  label: "Store link",
  addHoverButton: true,
  addToolbarButton: true,
  controls: {
    type: "autoform",
    schema: {
      required: ["storeId", "type"],
      type: "object",
      properties: {
        storeId: {
          type: "string",
          uniforms: {
            component: StoreIdSelector,
          },
        },
        type: {
          type: "string",
          enum: styledLinkEnum,
        },
        id: {
          type: "string",
          title: "Id (for analytics)",
        },
        anchor: {
          type: "string",
        },
        showShopMenu: {
          type: "boolean",
        },
      },
    },
  },
  getInitialData: () => ({
    type: "inline",
    storeId: "asdf",
  }),
  Component: (props) => (
    <StoreLink
      type={props.type ?? "inline"}
      storeId={props.storeId}
      id={props.id}
      hash={props.anchor}
      menuPath={props.showShopMenu ? SHOP_PATH : undefined}
    >
      {props.children}
    </StoreLink>
  ),
});
