import React, { Fragment } from "react";
import { LOG_CODE_LENGTH } from "../../../config/constants";

const ALPHANUMERIC_STRING_ALPHABET = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";

function generateAlphanumericString(length: number) {
  let code = "";
  const alphabetLength = ALPHANUMERIC_STRING_ALPHABET.length;

  for (let count = 0; count < length; count++) {
    const randomCharPos = Math.floor(Math.random() * alphabetLength);
    code += ALPHANUMERIC_STRING_ALPHABET.charAt(randomCharPos);
  }

  return code;
}

function generateLogCode() {
  return generateAlphanumericString(LOG_CODE_LENGTH);
}

function newLineToBreak(text) {
  if (!text) {
    return null;
  }

  const lines = text.split("\n");
  const lastIndex = lines.length - 1;

  return lines.map((line, index) => (
    <Fragment key={index}>
      {line}
      {index !== lastIndex ? <br /> : null}
    </Fragment>
  ));
}

export { generateAlphanumericString, generateLogCode, newLineToBreak };
