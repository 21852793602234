import documentLink from "./documentLink";
import pageLink from "./pageLink";
import storeLink from "./storeLink";
import externalLink from "./externalLink";
import categoryLink from "./categoryLink";
import productLink from "./productLink";
import anchorLink from "./anchorLink";
import { slatePlugins } from "@react-page/plugins-slate";

const links = {
  documentLink,
  pageLink,
  storeLink,
  externalLink,
  categoryLink,

  productLink,
  anchorLink,
  anchor: slatePlugins.link.anchor,
};

export default links;
