import { useQuery } from "@apollo/client";
import type { CellPlugin } from "@react-page/editor";
import gql from "graphql-tag";
import React from "react";
import styled from "styled-components";
import ProductSlider from "../../../product/components/ProductSlider";
import { BaseProductTileParts } from "../../../product/queries";
import { pluginBaseStyle } from "../../utils/pluginBaseStyle";
import type {
  GetFeaturedProducts,
  GetFeaturedProductsVariables,
  GetFeaturedProducts_assortment_searchProducts_products,
  GetFeaturedProducts_assortment_searchProducts_products_ShopConfigurableProduct,
} from "./types/GetFeaturedProducts";

const QUERY = gql`
  query GetFeaturedProducts($limit: Int) {
    assortment(assortmentId: "__BRANDS__") {
      _id
      searchProducts {
        products(limit: $limit) {
          ...BaseProductTileParts
        }
      }
    }
  }
  ${BaseProductTileParts}
`;

const Base = styled.div`
  ${pluginBaseStyle};
`;

const nbProducts = 12;

const isConfigurableProduct = (
  product: GetFeaturedProducts_assortment_searchProducts_products,
): product is GetFeaturedProducts_assortment_searchProducts_products_ShopConfigurableProduct =>
  product?.__typename === "ShopConfigurableProduct";

const Renderer = ({ data }) => {
  const { data: productData } = useQuery<
    GetFeaturedProducts,
    GetFeaturedProductsVariables
  >(QUERY, {
    variables: { limit: nbProducts },
  });

  const products =
    productData?.assortment?.searchProducts?.products.filter(
      isConfigurableProduct,
    ) ?? [];

  return (
    <Base>
      <ProductSlider
        title={data.title}
        products={products}
        backgroundColor="sand"
        maxProductCount={nbProducts}
      />
    </Base>
  );
};

const plugin: CellPlugin<{
  title: string;
}> = {
  Renderer,
  id: "featuredProducts",
  version: 1,
  // IconComponent: ,
  title: "Featured Products",

  description: "Featured Products",
  controls: {
    type: "autoform",
    columnCount: 1,
    schema: {
      required: ["title"],
      properties: {
        title: {
          type: "string",
          default: "Aktionen",
        },
      },
    },
  },
};
export default plugin;
