import React from "react";
import usePreventOnClickInEditor from "../hooks/usePreventOnClickInEditor";
import type { StyledLinkType } from "./StyledLink";
import StyledLink from "./StyledLink";

export interface ExternalLinkProps {
  url: string;
  type: StyledLinkType;
  openInNewTab?: boolean;
  id?: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  url,
  type,
  children,
  openInNewTab = true,
  id,
}) => {
  const onClick = usePreventOnClickInEditor();

  return (
    <StyledLink
      type={type}
      target={openInNewTab ? "_blank" : undefined}
      onClick={onClick}
      href={url}
      id={id}
    >
      {children}
    </StyledLink>
  );
};

export default ExternalLink;
