import type { EditorProps } from "@react-page/editor";
import { CONTENT_EDITOR_CELL_SPACING } from "../../../config/constants";
import footerNewsletter, {
  footerNewsletterSlate,
} from "../plugins/footerNewsletter";
import newsletterInput from "../plugins/newsletterInput";

const footerNewsletterConfig: EditorProps = {
  cellSpacing: CONTENT_EDITOR_CELL_SPACING,
  cellPlugins: [footerNewsletterSlate, newsletterInput, footerNewsletter],
};

export default footerNewsletterConfig;
