import dayjs from "dayjs";
import React from "react";
import Rectangle from "react-rectangle";
import styled from "styled-components";
import { useTranslation } from "../../../hooks/useTranslation";
import mediaQueries from "../../../utils/mediaQueries";
import {
  selectColor,
  selectFont,
  selectZIndex,
} from "../../../utils/themeUtils";
import type { GetBlogTeasers_blog } from "../../content/plugins/blogTeaser/types/GetBlogTeasers";
import LoadingPlaceholder from "../../core/components/LoadingPlaceholder";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import Heading from "../../layout/components/Heading";

const Base = styled.div``;

const LinkWrapper = styled.a`
  text-decoration: none;
  color: ${selectColor("black")};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: ${selectColor("sand")};
`;

const Date = styled.div`
  margin-top: 10px;
  ${selectFont("blogTeaserDate")}
`;

const Title = styled(Heading).attrs({ level: 3 })`
  margin-top: 10px;

  ${mediaQueries.tablet`
    margin-bottom: 10px;
  `}
`;

const Intro = styled.div`
  ${selectFont("blogTeaserIntro")};
  display: none;

  ${mediaQueries.tablet`
    display: block;
  `}
`;

const ReadMore = styled.span`
  display: inline-block;
  text-decoration: none;
  color: ${selectColor("petrol")};
  margin-top: 5px;
  position: relative;
  z-index: ${(props) => selectZIndex("scrollableGalleryButton")(props) + 1};
  ${selectFont("blogTeaserLink")}
`;

const TeaserPlaceholder = styled(LoadingPlaceholder)`
  height: 300px;
  width: 100%;

  ${mediaQueries.tablet`
    height: 390px
  `}
`;

export interface BlogTeaserProps {
  style?: React.CSSProperties;
  className?: string;
  data: GetBlogTeasers_blog;
}

const BlogTeaser: React.FC<BlogTeaserProps> = ({ style, className, data }) => {
  const { t } = useTranslation();
  const transitionKey = data ? "loaded" : "loading";

  return (
    <Base style={style} className={className}>
      <LoadingWrapper transitionKey={transitionKey}>
        {data ? (
          <LinkWrapper target="_blank" href={data.url}>
            <Rectangle aspectRatio={1.627906977}>
              <Image loading="lazy" src={data?.imageUrl} />
            </Rectangle>
            <Date>{dayjs(data.publishedAt).format("DD.MM.YYYY")}</Date>
            <Title>{data.title}</Title>
            <Intro>{data.intro}</Intro>
            <ReadMore>{t("readMore")}</ReadMore>
          </LinkWrapper>
        ) : (
          <TeaserPlaceholder />
        )}
      </LoadingWrapper>
    </Base>
  );
};

export default BlogTeaser;
