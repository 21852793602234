import React from "react";
import Rectangle from "react-rectangle";
import styled, { css } from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import {
  iff,
  selectColor,
  selectFont,
  selectSize,
} from "../../../utils/themeUtils";
import SectionHeading from "../../content/components/SectionHeading";
import { pluginBaseStyle } from "../../content/utils/pluginBaseStyle";
import ContentWrapper from "./ContentWrapper";
import type { Color } from "../../../config/theme";
import { getFocalPointBackgroundPosition } from "../../content/utils/getFocalPointBackgroundPosition";
import type { FocalPoint } from "../../admin/fileUpload/uploadFocalPoint";
export type Variant =
  | "normal"
  | "grey"
  | "article"
  | "product"
  | "minimal"
  | "minimalCompact";
export interface TeaserWithImageProps {
  style?: React.CSSProperties;
  className?: string;
  imageSrc: string;
  labelImageSrc?: string;
  heading?: string;
  addHeaderSpacing?: boolean;
  imageUseOriginalAspectRatio?: boolean;
  imageUseOriginalSize?: boolean;
  authorImageSrc?: string;
  variant?: Variant;
  focalPoint?: FocalPoint;
}

export const minHeightStyle = css`
  ${mediaQueries.desktop`
 min-height: ${selectSize("teaserMinHeight")}px;
  .react-page-cell-sm-3 &,
  .react-page-cell-sm-2 &,
  .react-page-cell-sm-1 & {
    min-height: ${selectSize("teaserMinHeightSmall")}px;
  }
`}
`;
const Base = styled.div<{ variant: Variant }>`
  ${pluginBaseStyle}
  max-width: 540px;

  ${iff(
    (p) =>
      p.variant === "minimal" ||
      p.variant === "minimalCompact" ||
      p.variant === "product",
  )`
    margin-bottom: 30px;

    ${mediaQueries.desktop`
      margin-bottom: 30px;
    `}
  `}

  ${iff((p) => p.variant === "product")`
    padding: 15px;
    background: white;
    border-radius: 5px;
  `}
`;

const Teaser = styled.div<{ variant: Variant }>`
  overflow: hidden;
  ${minHeightStyle};
  ${iff((p) => p.variant === "normal")`
    border-radius: 5px;
    box-shadow: 0 0 0 1.5px ${selectColor("black")};
    background-color: ${selectColor("white")};
  `};
  ${iff((p) => p.variant === "grey")`
    border-radius: 5px;
    background-color: ${selectColor("sand")};
  `}
  ${iff((p) => p.variant === "article")`
    background-color: ${selectColor("white")};
  `}

  p {
    ${selectFont("textDetails")};
  }

  ${iff(
    (p) =>
      p.variant === "minimal" ||
      p.variant === "minimalCompact" ||
      p.variant === "product",
  )`
    background-color: ${selectColor("white")};

     ${mediaQueries.desktop`
      min-height: 0;
      .react-page-cell-sm-3 &,
      .react-page-cell-sm-2 &,
      .react-page-cell-sm-1 & {
        min-height: 0;
      }
    `}

    p {
      margin-bottom: 0;
    }
  `}

  ${iff((p) => p.variant === "minimal")`
    p {
      ${selectFont("textContent")};
    }
  `}

  ${iff((p) => p.variant === "minimalCompact" || p.variant === "product")`
    h3 {
      ${selectFont("small")}
      margin-bottom: 0;
    }
  `}
`;

const ImageBase = styled.div<{ bgColor: Color }>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(p) => selectColor(p.bgColor)};
`;

const Image = React.memo(
  ({
    imageUseOriginalAspectRatio,
    imageUseOriginalSize,
    imageContain,
    imageSrc,
    imageBgColor,
    imageAspectRatio,
    focalPoint,
  }: {
    imageUseOriginalAspectRatio: boolean;
    imageUseOriginalSize: boolean;
    imageContain: boolean;
    imageSrc: string;
    imageBgColor: Color;
    imageAspectRatio: number;
    focalPoint: FocalPoint;
  }) => {
    return (
      <>
        {imageUseOriginalAspectRatio || imageUseOriginalSize ? (
          <img
            loading="lazy"
            alt=""
            src={imageSrc}
            style={{
              minWidth: 1,
              width: imageUseOriginalSize ? "auto" : "100%",
            }}
          />
        ) : (
          <Rectangle aspectRatio={imageAspectRatio}>
            <ImageBase
              bgColor={imageBgColor}
              style={{
                backgroundImage: imageSrc ? `url("${imageSrc}")` : null,
                backgroundSize: imageContain ? "contain" : "cover",
                backgroundPosition: getFocalPointBackgroundPosition(focalPoint),
              }}
            />
          </Rectangle>
        )}
      </>
    );
  },
);

const TeaserWithImage: React.FC<TeaserWithImageProps> = ({
  style,
  className,
  imageSrc,
  labelImageSrc,
  children,
  focalPoint,
  imageUseOriginalAspectRatio = false,
  imageUseOriginalSize = false,
  authorImageSrc,
  heading = "",
  addHeaderSpacing = false,
  variant = "normal",
}) => {
  const noHorizontalPadding =
    variant === "article" ||
    variant === "minimal" ||
    variant === "minimalCompact" ||
    variant === "product";
  const imageContain = variant === "product";
  const imageBgColor = variant === "product" ? "white" : "sand";
  const imageAspectRatio = variant === "product" ? 1.108 : 1.627906977;

  return (
    <Base style={style} className={className} variant={variant}>
      {heading || addHeaderSpacing ? (
        <SectionHeading>{heading}</SectionHeading>
      ) : null}
      <Teaser variant={variant}>
        <Image
          imageSrc={imageSrc}
          imageUseOriginalAspectRatio={imageUseOriginalAspectRatio}
          imageUseOriginalSize={imageUseOriginalSize}
          imageContain={imageContain}
          imageBgColor={imageBgColor}
          imageAspectRatio={imageAspectRatio}
          focalPoint={focalPoint}
        />
        <ContentWrapper
          variant={variant}
          noHorizontalPadding={noHorizontalPadding}
          labelImageSrc={labelImageSrc}
          authorImageSrc={authorImageSrc}
        >
          {children}
        </ContentWrapper>
      </Teaser>
    </Base>
  );
};

export default TeaserWithImage;
