import type { CellPlugin } from "@react-page/editor";
import React from "react";
import Brands from "../../../teaser/components/Brands";

const plugin: CellPlugin<{
  title: string;
  allBrandsLinkLabel: string;
}> = {
  Renderer: ({ data }) => {
    return <Brands title={data.title} />;
  },
  id: "brandsSlider",
  version: 1,
  // IconComponent: ,
  title: "Top Brands Slider",

  description: "Slider with top brands",
  controls: {
    type: "autoform",
    columnCount: 1,
    schema: {
      required: ["title"],
      properties: {
        title: {
          type: "string",
          default: "Entdecke über 500 Marken bei Veloplus!",
        },
        allBrandsLinkLabel: {
          type: "string",
        },
      },
    },
  },
};
export default plugin;
